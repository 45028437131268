/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import PageH1 from "../components/page-templates/page-h1"
import SanityContent from "../components/sanity/sanity-content"
import { useStaticQuery, graphql } from "gatsby"
import Card from "../components/page-templates/crisis-service-card"

const CrisisPage = () => {
  const data = useStaticQuery(graphql`
    {
      text: allSanityCrisisText(
        limit: 1
        sort: { fields: _updatedAt, order: DESC }
      ) {
        nodes {
          _rawBody
        }
      }
      services: allSanityServiceCategory(
        filter: { slug: { current: { eq: "crisis-counselling" } } }
      ) {
        nodes {
          services {
            id
            type
            geometry {
              type
              coordinates
            }
            properties {
              _id
              name
              slug
              summary
              excerpt
              email
              phone
              website
              categories {
                displayed
                name
                icon
                slug
              }
              features {
                displayed
                name
                icon
                slug
              }
              formats {
                displayed
                name
                icon
                slug
              }
              costs {
                displayed
                name
                icon
                slug
              }
              languages {
                displayed
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  const crisisServices =
    data.services.nodes[0] != null && data.services.nodes[0].services != null
      ? data.services.nodes[0].services
      : []

  const sortedCrisisServices = crisisServices.sort(function (a, b) {
    var nameA = a.properties.name
    var nameB = b.properties.name
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  return (
    <Layout>
      <Seo
        title="Crisis Resources"
        description="If you or someone you know is in immediate danger, call 911. If you or
        someone you know is having thoughts of suicide, call 1-800-784-2433
        (1-800-SUICIDE), to reach the Suicide.org suicide prevention line. This page also includes a listing of additional crisis and emergency mental health supports available in the province of BC."
      />
      <PageH1>Crisis Resources</PageH1>
      <SanityContent data={data.text.nodes[0]._rawBody} />
      <div
        sx={{
          width: "100vw",
          height: "auto",
          left: "calc(-50vw + 50%)",
          position: "relative",
        }}
      >
        <Themed.h2
          sx={{ mb: [3, null, 4, null, null], mx: [3, null, 4, null, null] }}
        >
          All listed crisis services
        </Themed.h2>

        <div
          sx={{
            mx: [3, null, 4, null, null],
            mb: 5,
            display: "grid",
            gridGap: 4,
            gridTemplateColumns: [
              "minmax(0, 1fr)",
              "repeat(auto-fit, minmax(400px, 1fr))",
              null,
              null,
              null,
            ],
          }}
        >
          {sortedCrisisServices.map((service) => (
            <Card service={service} key={service.id} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default CrisisPage
