/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { useContext } from "react"
import { Link } from "gatsby"
import { FiMap } from "react-icons/fi"
import { AiOutlineAlert } from "react-icons/ai"
import { lighten } from "@theme-ui/color"
import { NavContext } from "../../../../utils/context/nav-context"

// This component has a lot going on. It is handling the mapping of the menu items, optionally using anchor links, and optionally showing a dropdown menu. It is broken into smaller components for readability here but could be condensed into one mega component if you wanted.

const NavLinksRight = () => {
  const [isNavOpen, setIsNavOpen] = useContext(NavContext) // eslint-disable-line

  return (
    <ul
      sx={{
        mt: [2, null, 0, null, null],
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        listStyle: "none",
        m: 0,
        p: 0,
      }}
    >
      <li
        sx={{
          mr: 3,
          cursor: "pointer",
          ".active": {
            bg: lighten("primary", 0.5),
          },
          "@media screen and (min-width: 768px) and (max-width:800px)": {
            display: "none",
          },
        }}
      >
        <Themed.a
          as={Link}
          activeClassName="active"
          to="/map/"
          onClick={() => setIsNavOpen(false)}
          sx={{
            fontSize: [2, null, 1, 2, null],
            color: isNavOpen ? "header.textOpen" : "header.text",
            fontWeight: "bold",
            textDecoration: "none",
            py: 0,
            px: 3,
            borderColor: "primary",
            borderWidth: "2px",
            borderStyle: "solid",
            borderRadius: "9999em",
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s",
            ":hover, :focus, :active": {
              bg: lighten("primary", 0.5),
              color: "text",
            },
          }}
        >
          <FiMap />
          &nbsp;Map
        </Themed.a>
      </li>
      <li
        sx={{
          my: [2, null, 0, null, null],
          mr: [0, null, 3, null, null],
          cursor: "pointer",
          ".active": {
            bg: lighten("#ff0000", 0.4),
          },
        }}
      >
        <Themed.a
          as={Link}
          activeClassName="active"
          to="/crisis/"
          onClick={() => setIsNavOpen(false)}
          sx={{
            fontSize: [2, null, 1, 2, null],
            color: isNavOpen ? "header.textOpen" : "header.text",
            fontWeight: "bold",
            textDecoration: "none",
            py: 0,
            px: 3,
            borderColor: "#ff0000",
            borderWidth: "2px",
            borderStyle: "solid",
            borderRadius: "9999em",
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s",
            ":hover, :focus, :active": {
              bg: lighten("#ff0000", 0.4),
              color: "text",
            },
          }}
        >
          <AiOutlineAlert />
          &nbsp;Crisis
        </Themed.a>
      </li>
    </ul>
  )
}

export default NavLinksRight
