/** @jsx jsx */
import { jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useContext } from "react"
import { NavContext } from "../../../../utils/context/nav-context"
import { useSiteMetadata } from "../../../../utils/hooks/use-site-metadata"

const SiteLogo = () => {
  // Get all necessary variables
  const [isNavOpen, setIsNavOpen] = useContext(NavContext) //eslint-disable-line
  const { title, logo } = useSiteMetadata()
  return (
    <Link
      to="/"
      onClick={() => setIsNavOpen(false)}
      sx={{ textDecoration: "none" }}
    >
      <GatsbyImage
        image={logo}
        sx={{
          height: ["50px", "60px", null, "70px", null],
          width: ["100px", "110px", null, "130px", null],
          mr: 3,
        }}
        alt={title}
        imgStyle={{ objectFit: "contain" }}
      />
    </Link>
  )
}

export default SiteLogo
