/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import { Map, TileLayer, Marker } from "react-leaflet"
import L from "leaflet"
import "../map/map-custom.css"
import markerIconRetina from "leaflet/dist/images/marker-icon-2x.png"
import markerIcon from "leaflet/dist/images/marker-icon.png"
import markerIconShadow from "leaflet/dist/images/marker-shadow.png"

const ServiceTemplateMap = ({ lat, lng }) => {
  // Deal with the icons bug
  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: markerIconRetina,
      iconUrl: markerIcon,
      shadowUrl: markerIconShadow,
    })
  }, [])

  const markerPosition = [lat, lng]

  return (
    <Map
      center={[lat, lng]}
      zoom={13}
      style={{ height: "100%", width: "100%", marginBottom: "0" }}
      zoomControl={false}
      scrollWheelZoom={false}
      tap={false}
      dragging={false}
      touchZoom={false}
      doubleClickZoom={false}
      keyboard={false}
      boxZoom={false}
    >
      <TileLayer
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        url={`https://api.mapbox.com/styles/v1/${process.env.GATSBY_MAPBOX_USER_ID}/${process.env.GATSBY_MAPBOX_STREET_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.GATSBY_MAPBOX_API_KEY}`}
      />
      <Marker position={markerPosition} interactive={false} keyboard={false} />
    </Map>
  )
}

export default ServiceTemplateMap
