/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import RequestForm from "../components/forms/request-changes-form"
import PageH1 from "../components/page-templates/page-h1"
import { useStaticQuery, graphql } from "gatsby"
import SanityContent from "../components/sanity/sanity-content"

const RequestChanges = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      text: allSanityRequestChangesText(
        limit: 1
        sort: { fields: _updatedAt, order: DESC }
      ) {
        nodes {
          _rawBody
        }
      }
    }
  `)
  const hasServiceId = location.state && location.state.serviceId
  const serviceId = hasServiceId ? location.state.serviceId : null
  return (
    <Layout>
      <Seo
        title="Request changes"
        description="Thank-you for taking some time correct or update a database entry! We appreciate your help in keeping the information current. All change requests are reviewed by our team before changes are applied to the public database. We will contact you if we have any questions. MindMapBC is a custom database of mental health resources focused on LGBTQ2S+ health."
      />
      <PageH1>Request changes</PageH1>
      <SanityContent data={data.text.nodes[0]._rawBody} />
      <div sx={{ mb: 5 }}>
        <RequestForm serviceId={serviceId} />
      </div>
    </Layout>
  )
}

export default RequestChanges
