/** @jsx jsx */
import { jsx, Themed, Button, useThemeUI } from "theme-ui"
import { Link } from "gatsby"
import { useHomeContent } from "../../utils/hooks/use-home-content"
import { useServices } from "../../utils/hooks/use-services"
import HomeServiceCard from "../../components/home/home-service-card"
import ReactRough, { Circle } from "react-rough"

const HomeServices = () => {
  const { featuredServices, featuredServicesTitle } = useHomeContent()

  const { totalServices } = useServices()

  const { theme } = useThemeUI()
  const accentColor = theme.colors.accent

  return (
    <section
      sx={{
        width: "100vw",
        position: "relative",
        left: "calc(-50vw + 50%)",
        my: 6,
      }}
    >
      <div>
        <Themed.h2 sx={{ mx: 3, mb: 2, mt: 0, textAlign: "center" }}>
          {featuredServicesTitle}
        </Themed.h2>
        <div
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <ReactRough renderer="svg" width={72} height={18}>
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={9}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={36}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={63}
              y={9}
            />
          </ReactRough>
        </div>
        <div
          sx={{
            mx: [3, null, null, 4, null],
            display: "flex",
            justifyContent: "center",
            alignItems: ["center", null, "stretch", null, null],
            flexDirection: ["column", null, "row", null, null],
          }}
        >
          {featuredServices.map((service) => (
            <HomeServiceCard
              service={service}
              key={service.id}
              sx={{
                mr: [0, null, 3, 4, null],
                mb: [4, null, 0, null, null],
                maxWidth: "500px",
                ":last-of-type": { mr: 0, mb: 0 },
              }}
            />
          ))}
        </div>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            mx: 3,
            mt: 5,
          }}
        >
          <Button as={Link} to="/map/" variant="homeCta">
            View all {totalServices} services
          </Button>
        </div>
      </div>
    </section>
  )
}

export default HomeServices
