import { useStaticQuery, graphql } from "gatsby"

export const useServices = () => {
  const data = useStaticQuery(graphql`
    {
      geoJson: allServiceGeoJson {
        nodes {
          id
          type
          geometry {
            type
            coordinates
          }
          properties {
            _id
            name
            slug
            summary
            excerpt
            email
            phone
            website
            categories {
              displayed
              name
              icon
              slug
            }
            features {
              displayed
              name
              icon
              slug
            }
            formats {
              displayed
              name
              icon
              slug
            }
            costs {
              displayed
              name
              icon
              slug
            }
            languages {
              displayed
              name
              slug
            }
          }
        }
      }
    }
  `)

  // Get an array of all services
  const allServices = data.geoJson.nodes

  // Get the number of services
  const totalServices = allServices.length

  return {
    allServices,
    totalServices,
  }
}
