/** @jsx jsx */
import { jsx } from "theme-ui"
import { darken } from "@theme-ui/color"

const CheckboxError = ({ register, label, slug }) => {
  return (
    <div
      sx={{
        position: "relative",
      }}
    >
      <label
        sx={{
          fontSize: 1,
          lineHeight: "tight",
          cursor: "pointer",
          position: "relative",
          pl: "40px",
          display: "flex",
          alignItems: "center",
          height: "36px",
          // Hover styles for the checkbox
          ":hover input ~ .controlIndicator, input:focus ~ .controlIndicator": {
            bg: darken("muted", 0.15),
          },
          // Style when checked
          "input:checked ~ .controlIndicator": {
            bg: "primary",
          },
          // Displaying the checkbox
          "input:checked ~ .controlIndicator:after": {
            display: "block",
          },
          // Styles for when hovered and checked
          ":hover input:not([disabled]):checked ~ .controlIndicator, input:checked:focus ~ .controlIndicator":
            {
              bg: darken("primary", 0.15),
            },
        }}
      >
        {label}
        <input
          type="checkbox"
          {...register(`${slug}`)}
          sx={{
            position: "absolute",
            zIndex: "-1",
            opacity: "0",
          }}
        />
        <div
          className="controlIndicator"
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            height: "36px",
            width: "36px",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "primary",
            borderRadius: "9999em",
            bg: "muted",
            ":after": {
              content: `""`,
              position: "absolute",
              display: "none",
              left: "12px",
              top: "4px",
              width: "9px",
              height: "21px",
              borderColor: "#fff",
              borderStyle: "solid",
              borderWidth: "0 2px 2px 0",
              transform: "rotate(45deg)",
            },
          }}
        />
      </label>
    </div>
  )
}

export default CheckboxError
