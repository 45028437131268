/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link, graphql } from "gatsby"
import Seo from "../components/layout/seo"
import Layout from "../components/layout/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaRegClock } from "react-icons/fa"
import PageH1 from "../components/page-templates/page-h1"

const PostListTemplate = ({ data }) => {
  const rootPath = `/articles/` //Ensure trailing slash
  const posts = data.allSanityPost.nodes

  return (
    <Layout>
      <Seo title="Articles" />
      <PageH1>Articles</PageH1>
      <div sx={{ my: 5 }}>
        {posts.map((post) => (
          <article
            sx={{
              mb: 5,
              ":last-of-type": {
                mb: 0,
              },
            }}
            key={post.id}
          >
            <Themed.a
              as={Link}
              to={rootPath.concat(post.slug.current.replace(/\/*$/, `/`))}
            >
              <GatsbyImage
                image={post.featuredImage.asset.gatsbyImageData}
                alt={post.title}
                sx={{
                  height: ["200px", "250px", null, null, null],
                  borderRadius: "6px",
                }}
              />
            </Themed.a>

            <Themed.h2
              sx={{
                mt: 2,
                mb: 0,
                color: "text",
                fontSize: [4, null, null, 5, null],
                transition: "color 0.2s ease",
              }}
            >
              <Themed.a
                as={Link}
                to={rootPath.concat(post.slug.current.replace(/\/*$/, `/`))}
                sx={{
                  color: "text",
                  textDecoration: "none",
                  ":hover, :focus , :active": {
                    color: "text",
                    textDecoration: "underline",
                  },
                }}
              >
                {post.title}
              </Themed.a>
            </Themed.h2>

            <Themed.p sx={{ fontSize: 1, color: "textGray", m: 0, mt: 2 }}>
              {post.author}
              <br />
              {post.date} &bull;{" "}
              <FaRegClock
                sx={{
                  position: "relative",
                  top: "0.125em",
                }}
              />{" "}
              {post.readingTimeInMinutes} Min Read
            </Themed.p>
            <Themed.p sx={{ mt: 2, mb: 0 }}>{post.excerpt}</Themed.p>
            <Themed.p>
              <Themed.a
                as={Link}
                to={rootPath.concat(post.slug.current.replace(/\/*$/, `/`))}
              >
                Read More &rarr;
              </Themed.a>
            </Themed.p>
          </article>
        ))}
      </div>
    </Layout>
  )
}

export default PostListTemplate

export const query = graphql`
  query MMBCPostsQueryv2 {
    allSanityPost(sort: { fields: [date, title], order: DESC }) {
      nodes {
        id
        slug {
          current
        }
        title
        subTitle
        author
        date(formatString: "MMMM D, YYYY")
        excerpt(limit: 500)
        readingTimeInMinutes
        featuredImage {
          caption
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
