/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { useHomeContent } from "../../utils/hooks/use-home-content"

import { GatsbyImage } from "gatsby-plugin-image"

const HomePartnership = () => {
  const { partnershipLogos, partnershipTitle } = useHomeContent()

  return (
    <section
      sx={{
        width: "100vw",
        position: "relative",
        left: "calc(-50vw + 50%)",
        my: 5,
      }}
    >
      <div sx={{ mx: 3 }}>
        <Themed.h2
          sx={{
            textAlign: "center",
            fontSize: [1, 2, null, null, null],
            color: "textGray",
            letterSpacing: "wide",
            textTransform: "uppercase",
            mb: 3,
          }}
        >
          {partnershipTitle}
        </Themed.h2>
        <div
          sx={{
            // display: "grid",
            // alignItems: "center",
            // justifyContent: "center",
            // gridGap: [3, null, 3, null, null],
            // gridTemplateColumns: [
            //   "140px 140px",
            //   null,
            //   "140px 140px 140px 140px",
            //   null,
            //   null,
            // ],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {partnershipLogos.map((partner) => (
            <div
              key={partner.alt}
              sx={{
                maxWidth: "140px",
                minWidth: "100px",
                flex: "1",
                mr: 3,
                mb: 3,
                ":last-of-type": {
                  mr: 0,
                },
              }}
            >
              <a href={partner.link}>
                <GatsbyImage
                  image={partner.asset.gatsbyImageData}
                  alt={partner.alt}
                  objectFit="contain"
                  sx={{
                    height: "40px",
                  }}
                  imgStyle={{ objectFit: "contain" }}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HomePartnership
