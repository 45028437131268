/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Map from "./service-template-map"

const ServiceTemplateAddress = ({ service }) => {
  const hasAddress = service.address !== null
  const hasApartment = service.address.apartment !== null
  const hasLocation = service?.address?.location !== null
  const lat = service?.address?.location?.lat
  const lng = service?.address?.location?.lng

  return (
    <div>
      {hasAddress && (
        <div>
          <Themed.h2
            sx={{
              fontSize: [2, 3, null, null, null],
              mt: 0,
            }}
          >
            Address
          </Themed.h2>
          <Themed.p sx={{ fontSize: 1, lineHeight: "snug" }}>
            {hasApartment && `${service.address.apartment} - `}
            {`${service.address.address}`}
            <br />
            {`${service.address.city}, ${service.address.province}`}
            <br />
            {`${service.address.postal}`}
          </Themed.p>
          {hasLocation && (
            <div
              sx={{
                height: "200px",
                width: "300px",
                mt: 3,
                borderWidth: "1px",
                borderColor: "textGray",
                borderStyle: "solid",
              }}
            >
              <Map lat={lat} lng={lng} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ServiceTemplateAddress
