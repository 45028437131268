/** @jsx jsx */
import { jsx, Themed, Button } from "theme-ui"
import { useState, useEffect, useRef } from "react"
import { FiX } from "react-icons/fi"
import { alpha } from "@theme-ui/color"
import FocusLock from "react-focus-lock"
import { motion, AnimatePresence } from "framer-motion"
import ErrorForm from "../forms/error-form"

const ServiceTemplateErrorModal = ({ setIsErrorOpen, service }) => {
  // State to hold success
  const [isSuccessful, setIsSuccessful] = useState(false)

  //Modal Ref
  const modalRef = useRef()

  //Handle the click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [modalRef]) //eslint-disable-line

  // Close the modal on esc or submit on enter key
  useEffect(() => {
    const keyListener = (e) => {
      if (e.keyCode === 27 || e.keyCode === 13) {
        setIsErrorOpen(false)
      }
    }
    document.addEventListener("keydown", keyListener)
    return () => document.removeEventListener("keydown", keyListener)
  })

  //Handle close
  const handleClose = () => {
    setIsErrorOpen(false)
  }

  return (
    <AnimatePresence>
      <motion.div
        key="errorBackground"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          bg: alpha("text", 0.9),
          zIndex: "1000",
          width: "100vw",
          height: "100vh",
          display: "grid",
          alignItems: ["start", "center", null, null, null],
          justifyItems: "center",
        }}
      >
        <FocusLock>
          <div
            ref={modalRef}
            sx={{
              position: "relative",
              bg: "background",
              minWidth: ["90vw", "auto", null, null, null],
              borderWidth: "1px",
              borderColor: "primary",
              borderStyle: "solid",
              borderRadius: "6px",
              boxShadow: "lg",
              p: [3, null, 4, null, null],
              m: 3,
            }}
          >
            <Button
              aria-label="Close Error Modal"
              sx={{
                position: "absolute",
                top: "0",
                right: "0",
                bg: "transparent",
                color: "textGray",
                fontSize: [2, null, 3, null, null],
                border: "none",
                borderRadius: "9999em",
                p: 0,
                mx: 1,
                width: "32px",
                height: "32px",
                display: "grid",
                placeItems: "center",
                boxShadow: "none",
                ":hover, :active, :focus": {
                  bg: "muted",
                  border: "1px solid #aaa",
                },
              }}
              onClick={handleClose}
            >
              <FiX sx={{ fontSize: 3 }} />
            </Button>
            <Themed.h2
              sx={{
                fontSize: [2, 3, null, null, null],
                mb: [2, 4, null, null, null],
                mt: 0,
              }}
            >
              {isSuccessful ? "Submission received" : "Report error"}
            </Themed.h2>
            {isSuccessful ? (
              <div>
                <Themed.p sx={{ maxWidth: "720px" }}>
                  Thank-you very much for taking the time to improve the
                  accuracy of our database. You are a star! A member of our team
                  will review the error report and update the database as
                  necessary. Have an amazing day!
                </Themed.p>
                <Themed.a
                  as="button"
                  onClick={handleClose}
                  sx={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    textDecoration: "underline",
                    p: 0,
                  }}
                >
                  Close window &rarr;
                </Themed.a>
              </div>
            ) : (
              <ErrorForm setIsSuccessful={setIsSuccessful} service={service} />
            )}
          </div>
        </FocusLock>
      </motion.div>
    </AnimatePresence>
  )
}

export default ServiceTemplateErrorModal
