/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { darken } from "@theme-ui/color"

const BottomSheetHeader = ({ children, results }) => {
  // Pull out the unmapped results
  const unMappedResults = results.filter((service) => service.geometry == null)

  const numberOfUnMappedResults = unMappedResults.length
  const totalResults = results.length
  // Handle the result word so it is plural if greater than 1
  const pluralResults = totalResults !== 1 ? "services" : "service"

  return (
    <div
      sx={{
        bg: "background",
        position: "sticky",
        top: "0",
        width: "100%",
        pb: 2,
        borderBottomColor: "muted",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
      }}
    >
      <div
        sx={{
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          py: 2,
          bg: "primary",
          textAlign: "center",
          color: "white",
          mb: 3,
          zIndex: "2999",
        }}
      >
        <div
          sx={{
            bg: darken("primary", 0.15),
            height: "6px",
            width: "40px",
            boxShadow: "none",
            borderRadius: "9999em",
            borderStyle: "none",
            margin: "0 auto",
            ":hover, :active, :focus": {
              bg: darken("primary", 0.3),
              borderStyle: "none",
            },
          }}
        />
        <Themed.h1
          sx={{ color: "white", mt: 1, mb: 0, fontWeight: "bold", fontSize: 2 }}
        >
          {`${totalResults} ${pluralResults}`}{" "}
          <span
            sx={{ fontSize: 1, fontWeight: "normal" }}
          >{`(${numberOfUnMappedResults} without a location)`}</span>
        </Themed.h1>
      </div>
      {children}
    </div>
  )
}

export default BottomSheetHeader
