/** @jsx jsx */
import { jsx, Themed, Button } from "theme-ui"
import { useForm } from "react-hook-form"
import { FiAlertOctagon, FiLoader } from "react-icons/fi"
import { useCategories } from "../../utils/hooks/use-categories"
import { navigate } from "gatsby"
import Checkbox from "./checkbox-addService"
import { useSurveyQuestions } from "../../utils/hooks/use-survey-questions"
import SanityContent from "../sanity/sanity-content"

const AddServiceForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
  })

  // Get the survey question info

  const { surveyQuestionCategories, surveyMetadata } = useSurveyQuestions()

  // Get the category info
  const {
    serviceCategories,
    serviceFeatures,
    serviceLanguages,
    serviceCosts,
    serviceFormats,
  } = useCategories()

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const handleAddAService = (formData, event) => {
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "add-a-service", ...formData }),
    })
      .then((response) => {
        if (response.status === 200) {
          navigate("/submission-received/")
          reset()
        } else {
          navigate("/submission-error/")
        }
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    event.preventDefault()
  }

  return (
    <form
      onSubmit={handleSubmit(handleAddAService)}
      name="add-a-service"
      method="POST"
      action="/services/submission-received/"
      data-netlify="true"
      netlify-honeypot="got-ya"
    >
      <input type="hidden" name="form-name" value="add-a-service" />
      <input type="hidden" {...register("formId")} value="add-a-service" />
      <Themed.h2 sx={{ fontSize: [3, null, 4, null, null] }}>
        Your Information
      </Themed.h2>
      <label htmlFor="submitterName">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.submitterName ? "#ff0000" : "text",
          }}
        >
          Name <span sx={{ fontSize: 0, color: "textGray" }}>(required)</span>
        </Themed.p>
        {errors.submitterName && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required
          </Themed.p>
        )}
        <input
          {...register("submitterName", { required: true })}
          sx={{
            fontSize: 1,
            borderColor: errors.submitterName ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <label htmlFor="submitterEmail">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.submitterEmail ? "#ff0000" : "text",
            lineHeight: "tight",
          }}
        >
          Email <span sx={{ fontSize: 0, color: "textGray" }}>(required)</span>
        </Themed.p>
        {errors.submitterEmail && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required, please format as email@email.com
          </Themed.p>
        )}
        <input
          {...register("submitterEmail", {
            required: true,
            pattern:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          })}
          sx={{
            fontSize: 1,
            borderColor: errors.submitterEmail ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <label htmlFor="submitterOrg">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: "text",
          }}
        >
          Organization
        </Themed.p>
        <input
          {...register("submitterOrg")}
          sx={{
            fontSize: 1,
            borderColor: "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <Themed.h2 sx={{ fontSize: [3, null, 4, null, null] }}>
        Service Information
      </Themed.h2>
      <label htmlFor="name">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.name ? "#ff0000" : "text",
          }}
        >
          Service name{" "}
          <span sx={{ fontSize: 0, color: "textGray" }}>(required)</span>
        </Themed.p>
        {errors.name && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required
          </Themed.p>
        )}
        <input
          {...register("name", { required: true })}
          sx={{
            fontSize: 1,
            borderColor: errors.name ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <label htmlFor="phone">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.phone ? "#ff0000" : "text",
            lineHeight: "tight",
          }}
        >
          Phone number
        </Themed.p>
        {errors.phone && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Please format as 123-456-7890
          </Themed.p>
        )}
        <input
          {...register("phone", {
            pattern: /^(?:\d{1}[-])?(\d{3})(?:[-])(\d{3})[-](\d{4})$/,
          })}
          sx={{
            fontSize: 1,
            borderColor: errors.phone ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <label htmlFor="email">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.email ? "#ff0000" : "text",
            lineHeight: "tight",
          }}
        >
          Email
        </Themed.p>
        {errors.email && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Please format as email@email.com
          </Themed.p>
        )}
        <input
          {...register("email", {
            pattern:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          })}
          sx={{
            fontSize: 1,
            borderColor: errors.email ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <label htmlFor="website">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.website ? "#ff0000" : "text",
            lineHeight: "tight",
          }}
        >
          Website
        </Themed.p>
        {errors.website && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Please enter a complete website address
          </Themed.p>
        )}
        <input
          {...register("website", {
            pattern:
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, //eslint-disable-line
          })}
          sx={{
            fontSize: 1,
            borderColor: errors.website ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <fieldset sx={{ mb: 3 }}>
        <legend sx={{ fontSize: 1 }}>Address</legend>
        <Themed.p
          sx={{
            fontStyle: "italic",
            fontSize: 0,
            color: "textGray",
            lineHeight: "none",
            mt: 0,
          }}
        >
          An address is required to be added to the map, but entries without an
          address are still included in the database as some services (e.g.
          online only services), do not have an address.
        </Themed.p>
        <div>
          <label htmlFor="address">
            <Themed.p
              sx={{
                fontSize: 1,
                m: 0,
              }}
            >
              Street Address
            </Themed.p>
            <input
              {...register("address")}
              sx={{
                fontSize: 1,
                borderColor: "textGray",
                borderRadius: "6px",
                borderWidth: "2px",
                borderStyle: "solid",
                p: 1,
                maxWidth: "250px",
                width: "100%",
                mt: 1,
                mb: 3,
              }}
            />
          </label>
          <label htmlFor="apartment">
            <Themed.p
              sx={{
                fontSize: 1,
                m: 0,
              }}
            >
              Suite/Apartment
            </Themed.p>
            <input
              {...register("apartment")}
              sx={{
                fontSize: 1,
                borderColor: "textGray",
                borderRadius: "6px",
                borderWidth: "2px",
                borderStyle: "solid",
                p: 1,
                maxWidth: "250px",
                width: "100%",
                mt: 1,
                mb: 3,
              }}
            />
          </label>
          <label htmlFor="city">
            <Themed.p
              sx={{
                fontSize: 1,
                m: 0,
              }}
            >
              City or town
            </Themed.p>
            <input
              {...register("city")}
              sx={{
                fontSize: 1,
                borderColor: "textGray",
                borderRadius: "6px",
                borderWidth: "2px",
                borderStyle: "solid",
                p: 1,
                maxWidth: "250px",
                width: "100%",
                mt: 1,
                mb: 3,
              }}
            />
          </label>
          <label htmlFor="province">
            <Themed.p
              sx={{
                fontSize: 1,
                m: 0,
              }}
            >
              Province
            </Themed.p>
            <select
              {...register("province")}
              sx={{
                fontSize: 1,
                borderColor: "textGray",
                borderRadius: "6px",
                borderWidth: "2px",
                borderStyle: "solid",
                p: 1,
                maxWidth: "250px",
                width: "100%",
                mt: 1,
                mb: 3,
              }}
            >
              <option value="">--Please choose a province--</option>
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland and Labrador</option>
              <option value="NT">Northwest Territories</option>
              <option value="NS">Nova Scotia</option>
              <option value="NU">Nunavut</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="YT">Yukon</option>
            </select>
          </label>
          <label htmlFor="postal">
            <Themed.p
              sx={{
                fontSize: 1,
                m: 0,
                color: errors.postal ? "#ff0000" : "text",
                lineHeight: "tight",
              }}
            >
              Postal Code
            </Themed.p>
            {errors.postal && (
              <Themed.p
                sx={{
                  fontSize: 0,
                  color: "#ff0000",
                  m: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FiAlertOctagon /> &nbsp;Please format as A1B 2C3
              </Themed.p>
            )}
            <input
              {...register("postal", {
                pattern: /([ABCEGHJ-NPRSTVXY]\d[A-Z])[\s\-]?(\d[A-Z]\d)/i, //eslint-disable-line
              })}
              sx={{
                fontSize: 1,
                borderColor: errors.postal ? "#ff0000" : "textGray",
                borderRadius: "6px",
                borderWidth: "2px",
                borderStyle: "solid",
                p: 1,
                maxWidth: "250px",
                width: "100%",
                mt: 1,
                mb: 3,
              }}
            />
          </label>
        </div>
      </fieldset>
      <label htmlFor="summary">
        <Themed.p
          sx={{
            fontSize: 1,
            mt: 0,
            lineHeight: "tight",
            color: errors.summary ? "#ff0000" : "text",
          }}
        >
          Summary{" "}
          <span sx={{ fontSize: 0, color: "textGray" }}>(required)</span>
          <br />
          <span
            sx={{
              fontStyle: "italic",
              fontSize: 0,
              color: "textGray",
              lineHeight: "none",
            }}
          >
            Please be brief, typically 1 - 2 paragraphs is sufficient. Please do
            not include information that is time limited or too specific, for
            example information about upcoming program dates or times. A good
            summary gives a feel for what a person can expect while connected to
            your services and invites further contact.
          </span>
        </Themed.p>
        {errors.summary && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required
          </Themed.p>
        )}
        <textarea
          rows="12"
          {...register("summary", { required: true })}
          sx={{
            resize: "none",
            fontSize: 1,
            borderColor: errors.summary ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <fieldset sx={{ mb: 3 }}>
        <legend sx={{ fontSize: 1 }}>Costs</legend>
        <div sx={{ display: "flex", flexWrap: "wrap", my: 3 }}>
          {serviceCosts.map((cost) => (
            <Checkbox
              checkbox={cost}
              register={register}
              key={cost.id}
              prefix="costs"
            />
          ))}
        </div>
      </fieldset>
      <fieldset sx={{ mb: 3 }}>
        <legend sx={{ fontSize: 1 }}>Features</legend>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "minmax(0, 1fr)",
              null,
              "minmax(0, 1fr) minmax(0, 1fr)",
              null,
              null,
            ],
            my: 3,
          }}
        >
          {serviceFeatures.map((cost) => (
            <Checkbox
              checkbox={cost}
              register={register}
              key={cost.id}
              prefix="features"
            />
          ))}
        </div>
      </fieldset>
      <fieldset sx={{ mb: 3 }}>
        <legend sx={{ fontSize: 1 }}>Service Types</legend>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "minmax(0, 1fr)",
              null,
              "minmax(0, 1fr) minmax(0, 1fr)",
              null,
              null,
            ],
            my: 3,
          }}
        >
          {serviceCategories.map((category) => (
            <Checkbox
              prefix="categories"
              checkbox={category}
              register={register}
              key={category.id}
            />
          ))}
        </div>
      </fieldset>
      <fieldset sx={{ mb: 3 }}>
        <legend sx={{ fontSize: 1 }}>Formats</legend>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "minmax(0, 1fr)",
              null,
              "minmax(0, 1fr) minmax(0, 1fr)",
              null,
              null,
            ],
            my: 3,
          }}
        >
          {serviceFormats.map((format) => (
            <Checkbox
              checkbox={format}
              register={register}
              key={format.id}
              prefix="formats"
            />
          ))}
        </div>
      </fieldset>

      <fieldset sx={{ mb: 3 }}>
        <legend sx={{ fontSize: 1 }}>Languages</legend>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "minmax(0, 1fr)",
              "minmax(0, 1fr) minmax(0, 1fr)",
              "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
              null,
              null,
            ],
            my: 3,
          }}
        >
          {serviceLanguages.map((language) => (
            <Checkbox
              checkbox={language}
              register={register}
              key={language.id}
              prefix="languages"
            />
          ))}
        </div>
      </fieldset>
      <Themed.h2 sx={{ fontSize: [3, null, 4, null, null] }}>
        {surveyMetadata.surveyFormTitle}
      </Themed.h2>
      <div
        sx={{
          p: {
            fontSize: 1,
          },
        }}
      >
        <SanityContent data={surveyMetadata._rawSurveyFormIntro} />
      </div>
      {surveyQuestionCategories.map((surveyCategory) => (
        <fieldset key={surveyCategory.id} sx={{ mb: 3 }}>
          <legend sx={{ fontSize: 1 }}>{surveyCategory.title}</legend>
          {surveyCategory.questions.map((question, questionIndex) => (
            <div
              key={question.id}
              sx={{
                bg: "mutedGreen",
                px: 3,
                py: 2,
                borderRadius: "4px",
                mb: 4,
              }}
            >
              <Themed.p sx={{ fontSize: 1 }}>{question.question}</Themed.p>
              <input
                type="hidden"
                {...register(`surveyQuestion-${question._id}`)}
                value={question.question}
              />
              {question.answers.map((answer, index) => (
                <label
                  key={`${index}-${answer}`}
                  sx={{ display: "block" }}
                  htmlFor={`surveyQuestion-${question._id}`}
                >
                  <input
                    type="radio"
                    {...register(`surveyAnswer-${question._id}`)}
                    value={answer}
                    sx={{ mr: 2 }}
                  />
                  {answer}
                </label>
              ))}
              <div sx={{ mt: 2 }}>
                <label
                  htmlFor={`surveyComment-${question._id}`}
                  sx={{ fontSize: 0, color: "textGray" }}
                >
                  Comments
                  <textarea
                    rows="4"
                    {...register(`surveyComment-${question._id}`)}
                    sx={{
                      resize: "none",
                      fontSize: 1,
                      borderColor: errors.summary ? "#ff0000" : "textGray",
                      borderRadius: "6px",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      p: 1,
                      width: "100%",
                      mt: 1,
                      mb: 3,
                    }}
                  />
                </label>
              </div>
            </div>
          ))}
        </fieldset>
      ))}
      <label
        htmlFor="got-ya"
        sx={{
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: "1px",
          width: "1px",
          margin: "-1px",
          padding: "0",
          border: "0",
        }}
      >
        Don’t fill this out if you're human:
        <input tabIndex="-1" {...register("got-ya")} />
      </label>
      <div
        sx={{
          display: "flex",
          mt: 4,
          justifyContent: ["center", null, "start", null, null],
        }}
      >
        <Button
          type="submit"
          variant="submit"
          disabled={isSubmitSuccessful ? true : false}
          sx={{
            width: "120px",
            height: "40px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: isSubmitSuccessful ? 0.5 : 1,
            cursor: isSubmitSuccessful ? "wait" : "pointer",
            ":after": { content: isSubmitSuccessful && "''" },
          }}
        >
          {isSubmitSuccessful ? (
            <FiLoader
              sx={{
                fontSize: "3",
                animation: "spin infinite 5s linear",
                "@keyframes spin": {
                  from: {
                    transform: "rotate(0deg)",
                  },
                  to: {
                    transform: "rotate(360deg)",
                  },
                },
              }}
            />
          ) : (
            `Submit`
          )}
        </Button>
      </div>
    </form>
  )
}

export default AddServiceForm
