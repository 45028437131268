/** @jsx jsx */
import { jsx, Themed, useThemeUI, Button } from "theme-ui"
import { useHomeContent } from "../../utils/hooks/use-home-content"
import SanityContent from "../sanity/sanity-content"
import { GatsbyImage } from "gatsby-plugin-image"
import { RoughNotation } from "react-rough-notation"
import { alpha } from "@theme-ui/color"
import ReactRough, { Circle } from "react-rough"
import { Link } from "gatsby"

const HomeAbout = () => {
  const {
    aboutTitle,
    aboutTitle1,
    aboutContent1,
    aboutImage1,
    aboutTitle2,
    aboutContent2,
    aboutImage2,
  } = useHomeContent()

  const { theme } = useThemeUI()
  const primaryColor = theme.colors.primary
  const accentColor = theme.colors.accent

  return (
    <section
      sx={{
        width: "100vw",
        position: "relative",
        left: "calc(-50vw + 50%)",
        my: 6,
      }}
    >
      <div sx={{ maxWidth: "1440px", mx: "auto" }}>
        <Themed.h2 sx={{ mb: 2, mx: 3, textAlign: "center" }}>
          {aboutTitle}
        </Themed.h2>
        <div
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <ReactRough renderer="svg" width={72} height={18}>
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={9}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={36}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={63}
              y={9}
            />
          </ReactRough>
        </div>
        <div sx={{ mx: [3, null, 5, null, null] }}>
          <div
            sx={{
              display: "flex",
              mb: [5, null, 6, null, null],
              alignItems: "center",
              flexDirection: ["column", null, "row", null, null],
            }}
          >
            <RoughNotation
              type="box"
              show={true}
              animate={false}
              color={primaryColor}
              iterations={3}
              strokeWidth={1}
              sx={{
                flex: 1,
                mr: [0, null, 5, null, null],
                mb: [4, null, 0, null, null],
              }}
            >
              <div
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <GatsbyImage
                  image={aboutImage1.asset.gatsbyImageData}
                  alt={aboutImage1.alt}
                  sx={{
                    position: "relative",
                    zIndex: "5",
                    borderRadius: "6px",
                    height: ["200px", null, "350px", null, null],
                  }}
                />
                <figcaption
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    zIndex: "10",
                    color: "white",
                    px: 2,
                    fontSize: 0,
                    backgroundColor: alpha("primary", 0.8),
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                  }}
                >
                  {aboutImage1.caption}
                </figcaption>
              </div>
            </RoughNotation>
            <div sx={{ flex: 2 }}>
              <Themed.h3>{aboutTitle1}</Themed.h3>
              <SanityContent data={aboutContent1} />
            </div>
          </div>
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: ["column", null, "row", null, null],
            }}
          >
            <div sx={{ flex: 2, order: [2, null, 1, null, null] }}>
              <Themed.h3>{aboutTitle2}</Themed.h3>
              <SanityContent data={aboutContent2} />
            </div>
            <RoughNotation
              type="box"
              show={true}
              animate={false}
              color={primaryColor}
              iterations={3}
              strokeWidth={1}
              sx={{
                flex: 1,
                ml: [0, null, 5, null, null],
                order: [1, null, 2, null, null],
                mb: [4, null, 0, null, null],
              }}
            >
              <div
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <GatsbyImage
                  image={aboutImage2.asset.gatsbyImageData}
                  alt={aboutImage2.alt}
                  sx={{
                    borderRadius: "6px",
                    height: ["200px", null, "350px", null, null],
                    position: "relative",
                    zindex: "5",
                  }}
                />
                <figcaption
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    zIndex: "10",
                    color: "white",
                    px: 2,
                    fontSize: 0,
                    backgroundColor: alpha("primary", 0.8),
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                  }}
                >
                  {aboutImage2.caption}
                </figcaption>
              </div>
            </RoughNotation>
          </div>
          <div
            sx={{
              mx: 3,
              mt: [4, null, 5, null, null],
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button as={Link} to="/about-us/" variant="homeCta">
              More about this project
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
