/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import { mediaStyles } from "../../utils/media"
import GlobalCSS from "../../css/global-css"
import "./map-custom.css"
import { Media } from "../../utils/media"
import Header from "../layout/header/header"
import { Fragment } from "react"

const MapLayout = ({ children }) => {
  return (
    <Fragment>
      <GlobalCSS />
      <Helmet>
        <style>{mediaStyles}</style>
      </Helmet>
      <div
        sx={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          overflowY: "hidden",
        }}
      >
        <SkipNavLink />
        <Media greaterThanOrEqual="md">
          <Header />
        </Media>
        <main sx={{ height: "100%" }}>
          <SkipNavContent />
          {children}
        </main>
      </div>
    </Fragment>
  )
}

export default MapLayout
