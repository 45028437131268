/** @jsx jsx */
import { jsx, Themed, useThemeUI } from "theme-ui"
import { RoughNotation } from "react-rough-notation"

const PageH1 = (props) => {
  const { theme } = useThemeUI()
  const primaryColor = theme.colors.primary
  return (
    <Themed.h1 {...props}>
      <RoughNotation
        type="underline"
        show={true}
        color={primaryColor}
        strokeWidth={4}
      >
        {props.children}
      </RoughNotation>
    </Themed.h1>
  )
}

export default PageH1
