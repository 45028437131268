import React, { useState, createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityAlertBanner(
        limit: 1
        sort: { fields: _updatedAt, order: DESC }
      ) {
        nodes {
          displayed
        }
      }
    }
  `)
  const alertStatus = data.allSanityAlertBanner.nodes[0].displayed
  const [displayAlert, setDisplayAlert] = useState(alertStatus)
  return (
    <AlertContext.Provider value={[displayAlert, setDisplayAlert]}>
      {children}
    </AlertContext.Provider>
  )
}
