/** @jsx jsx */
import { jsx } from "theme-ui"
import { useRef, useEffect, useState } from "react"
import {
  Map,
  TileLayer,
  FeatureGroup,
  ZoomControl,
  GeoJSON,
  LayersControl,
} from "react-leaflet"
import L from "leaflet"
import Locate from "leaflet.locatecontrol"
import MarkerClusterGroup from "react-leaflet-markercluster"
import indigenousTerritories from "./indigenousTerritories.json"
import "./map-custom.css"
import { useContextValues } from "../../utils/hooks/use-context-values"

const MapLeaflet = () => {
  // Get context from useSearch
  const { allResults, setVisibleMarkers } = useContextValues()
  // Initiate map ref
  const mapRef = useRef()
  const groupRef = useRef()
  const clusterRef = useRef()

  // Get the mapped results from all results to use in the map
  const mappedResults = allResults.filter(
    (service) =>
      service.geometry !== null && service.geometry.coordinates !== null
  )

  // GeoJson Key to handle updating geojson inside react-leaflet
  const [geoJsonKey, setGeoJsonKey] = useState("initialKey123abc")

  // Set the bounds of the map based on the custom Layer
  const fitToCustomLayer = () => {
    if (mappedResults.length > 0 && mapRef.current && groupRef.current) {
      const map = mapRef.current.leafletElement
      const layer = groupRef.current.leafletElement
      if (layer) {
        map.fitBounds(layer.getBounds().pad(0.5))
      }
    }
  }

  // Add leaflet locate control to the map
  const addLocate = () => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement
      const locateOptions = {
        position: "bottomright",
        flyTo: true,
        drawCircle: false,
        showPopup: false,
        icon: "mmbc-locate-icon",
        iconLoading: "mmbc-locate-icon-loading",
      }
      const lc = new Locate(locateOptions)
      lc.addTo(map)
    }
  }

  // Deal with the icons bug
  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    })
  }, [])

  // Generate a new key to force an update to GeoJson Layer
  useEffect(() => {
    const makeKey = (length) => {
      let result = ""
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    }
    const newKey = makeKey(10)
    setGeoJsonKey(newKey)
  }, [allResults])

  // useEffect Hook to update visible features and reset view when geoJson changes
  useEffect(() => {
    fitToCustomLayer()
  }, [geoJsonKey]) //eslint-disable-line

  //Creating popups for the map
  const createPopups = (feature = {}, layer) => {
    const { properties = {} } = feature
    const { name, slug, categories, website, phone } = properties
    const displayedPhone =
      phone != null
        ? `<li>
          <strong>Phone:</strong> ${phone}
        </li>`
        : ""
    const displayedWebsite =
      website != null
        ? `<li>
          <strong>Website:</strong>
          <a href=${website} target="_blank" rel="noopener noreferrer">
            ${website}
          </a>
        </li>`
        : ""
    const categoryNames = categories.map((category) => category.name)
    const popup = L.popup()
    const html = `
    <div class="mmbc-pop-up">
    <h3><a href="/services/${slug}">${name}</a></h3>
    <ul>
    <li><strong>Services:</strong> ${categoryNames.join(", ")}</li>
    ${displayedPhone}
    ${displayedWebsite}
    </div>
    `
    popup.setContent(html)
    layer.bindPopup(popup)
  }

  //Creating popups for Indigenous Territories
  const createIndigenousPopups = (feature = {}, layer) => {
    const { properties = {} } = feature
    const popup = L.popup()
    const html = `
    <div class="mmbc-pop-up">
    <h3>${properties.Name}</h3>
    </div>
    `
    popup.setContent(html)
    layer.bindPopup(popup)
  }

  const createIndigenousPolygonStyles = (feature) => {
    return {
      fillColor: feature.properties.color,
      color: feature.properties.color,
    }
  }

  // Create individual markers
  const createMarker = (geoJsonPoint, latlng) => {
    return L.marker(latlng, {
      icon: L.divIcon({
        html: `<div><span><b>1</b></span></div>`,
        className: "mmbc-marker-cluster mmbc-marker-cluster-single",
        iconSize: new L.point(40, 40),
      }),
    })
  }

  // Handle creation of clusters and change styles
  const handleClusters = (cluster) => {
    const childCount = cluster.getChildCount()
    let c = " mmbc-marker-cluster-"
    if (childCount < 10) {
      c += "small"
    } else if (childCount < 50) {
      c += "medium"
    } else {
      c += "large"
    }
    return L.divIcon({
      html: `<div><span><b>${childCount}</b></span></div>`,
      className: "mmbc-marker-cluster" + c,
      iconSize: new L.point(40, 40),
    })
  }

  // Get visible services in the map
  const getVisibleServices = () => {
    if (mapRef.current && groupRef.current && clusterRef.current) {
      const cluster = clusterRef.current.leafletElement
      const map = mapRef.current.leafletElement
      var features = []
      cluster.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          if (map.getBounds().contains(layer.getLatLng())) {
            features.push(layer.feature)
          }
        }
      })
      setVisibleMarkers(features)
    }
  }

  return (
    <Map
      center={[54.592307, -125.305227]}
      zoom={5}
      ref={mapRef}
      style={{ height: "100%", width: "100%", marginBottom: "0" }}
      useFlyTo={true}
      zoomControl={false}
      whenReady={addLocate}
      onZoomEnd={getVisibleServices}
      onMoveEnd={getVisibleServices}
      tap={false}
    >
      <ZoomControl position="bottomright" />
      <LayersControl position="bottomright" hideSingleBase={true}>
        <LayersControl.BaseLayer checked name="Street View">
          <TileLayer
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
            url={`https://api.mapbox.com/styles/v1/${process.env.GATSBY_MAPBOX_USER_ID}/${process.env.GATSBY_MAPBOX_STREET_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.GATSBY_MAPBOX_API_KEY}`}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Indigenous Territories">
          <GeoJSON
            data={indigenousTerritories}
            onEachFeature={createIndigenousPopups}
            style={createIndigenousPolygonStyles}
          />
        </LayersControl.Overlay>
      </LayersControl>
      <FeatureGroup ref={groupRef} name="Services">
        {mappedResults.length > 0 && (
          <MarkerClusterGroup
            ref={clusterRef}
            iconCreateFunction={handleClusters}
            showCoverageOnHover={false}
            singleMarkerMode={true}
          >
            <GeoJSON
              data={mappedResults}
              key={geoJsonKey}
              onEachFeature={createPopups}
              pointToLayer={createMarker}
            />
          </MarkerClusterGroup>
        )}
      </FeatureGroup>
    </Map>
  )
}

export default MapLeaflet

//  // First run of the get features in view function for the map
//  const getVisibleServices = () => {
//   if (mappedResults.length > 0 && mapRef.current && groupRef.current) {
//     const map = mapRef.current.leafletElement
//     var features = []
//     // Handle creation of clusters and get the markers inside

//     map.eachLayer(function (layer) {
//       if (layer instanceof L.Marker) {
//         if (layer.feature && map.getBounds().contains(layer.getLatLng())) {
//           features.push(layer.feature)
//         }
//       }
//     })
//     setVisibleServices(features)
//   } else {
//     setVisibleServices([])
//   }
// }

// // Handle creation of clusters and get the markers inside
// const handleClusters = (cluster) => {
//   const clusterChildMarkers = cluster.getAllChildMarkers() // list of all markers
//   return L.divIcon({
//     html: `<div><span><b>${cluster.getChildCount()}</b></span></div>`,
//     className: "marker-cluster-medium marker-cluster",
//     iconSize: L.point(40, 40, true),
//   })
// }
// // Viewport state to manage changes to viewport
// // eslint-disable-next-line
// const [viewport, setViewport] = useState({
//   center: [54.592307, -125.305227],
//   zoom: 5,
//   hasLocation: false,
// })
