/** @jsx jsx */
import { jsx } from "theme-ui"
import { useContext } from "react"
import { NavContext } from "../../../../utils/context/nav-context"

const NavLiDropdown = ({ children }) => {
  const [isNavOpen] = useContext(NavContext)

  return (
    <li
      sx={{
        fontSize: [1, 2, null, null, null],
        display: "block",
        mb: isNavOpen ? 1 : 2,
        ":hover": {
          cursor: "pointer",
        },
        a: {
          color: isNavOpen ? "header.textOpen" : "header.text",
          textDecoration: "none",
          ":hover, :focus, :active": {
            textDecoration: "underline",
            color: "text",
            textDecorationThickness: "0.125em",
          },
          ":after": {
            content: "none",
          },
        },
        ".active": {
          textDecoration: "underline",
          textDecorationThickness: "0.125em",
          color: "primary",
          variant: "variants.navLinkSubActive",
          ":after": {
            content: "none",
          },
        },
      }}
    >
      {children}
    </li>
  )
}

export default NavLiDropdown
