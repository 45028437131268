/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import { darken } from "@theme-ui/color"
import HomeServiceTag from "./home-service-tag"

const HomeServiceCard = ({ service, ...props }) => {
  const allTags = [
    ...service.categories,
    ...service.costs,
    ...service.features,
    ...service.formats,
  ]

  return (
    <Themed.a
      as={Link}
      to={`/services/${service.slug.current}`}
      key={service.id}
      sx={{
        color: "text",
        textDecoration: "none",
        ":hover": {
          color: "text",
        },
      }}
      {...props}
    >
      <div
        sx={{
          bg: "mutedGreen",
          p: 3,
          borderRadius: "6px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          position: "relative",
          top: "0",
          transition: "all .1s ease-in",
          height: "100%",
          ":hover": {
            top: "-4px",
            boxShadow: "0 4px 5px rgba(0,0,0,0.2)",
            bg: darken("mutedGreen", 0.05),
          },
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <Themed.h3 sx={{ fontSize: 3 }}>{service.name}</Themed.h3>
            <ul
              sx={{
                listStyle: "none",
                m: 0,
                p: 0,
                mb: 3,
                lineHeight: "tight",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {allTags.map((category) => (
                <HomeServiceTag
                  category={category}
                  key={category.slug.current}
                />
              ))}
            </ul>
            <Themed.p>{service.excerpt}</Themed.p>
          </div>
          <Themed.p sx={{ textDecoration: "underline" }}>
            View more &rarr;
          </Themed.p>
        </div>
      </div>
    </Themed.a>
  )
}

export default HomeServiceCard
