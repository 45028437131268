/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"

const HomeServiceTag = ({ category, ...props }) => {
  const hasIcon =
    category.icon != null &&
    category.icon.asset.url != null &&
    category.icon.asset.url !== ""

  return (
    <li
      sx={{
        display: "flex",
        alignItems: "center",
        ":after": {
          content: "'\\002C \\00A0'",
        },
        ":last-of-type:after": { content: "''" },
      }}
    >
      {hasIcon && (
        <Fragment>
          <img
            src={category.icon.asset.url}
            alt={category.name}
            height="18"
            width="18"
          />
          &nbsp;
        </Fragment>
      )}
      <span
        sx={{
          fontSize: [0, 1, null, null, null],
        }}
      >
        {category.name}
      </span>
    </li>
  )
}
export default HomeServiceTag
