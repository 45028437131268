/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useRef, useState } from "react"
import Seo from "../components/layout/seo"
import { useHasMounted } from "../utils/hooks/use-has-mounted"
import { useServices } from "../utils/hooks/use-services"
import { useClearedFilters } from "../utils/hooks/use-cleared-filters"
import { Media } from "../utils/media"
import MapLayout from "../components/map/map-layout"
import MapLeaflet from "../components/map/map-leaflet"
import MapBottomSheet from "../components/map/map-bottomsheet"
import MapBottomSheetHeader from "../components/map/map-bottomsheet-header"
import FilterModal from "../components/filter/filter-modal"
import MapFilterMenu from "../components/filter/filter-menu"
import SearchForm from "../components/search/search-form"
import SearchResults from "../components/search/search-results"
import SearchActiveFilters from "../components/search/search-active-filters"
import SearchNumberOfResults from "../components/search/search-number-of-results"
import Footer from "../components/layout/footer"
import { Helmet } from "react-helmet"
import { useSearch } from "../utils/hooks/use-search"
import { useContextValues } from "../utils/hooks/use-context-values"

const MapPage = () => {
  // Get functions from useSearch
  const { handleSearch, handleSearchWithFilters } = useSearch()

  // Get state and setState
  const {
    hasPopularFilters,
    fuseSearchList,
    setSearchResults,
    setAllResults,
    setHasQuery,
    isFilterOpen,
    searchQuery,
    setHasPopularFilters,
    setFilterValues,
    allResults,
    visibleMarkers,
    hasQueryFromIndex,
    setHasQueryFromIndex,
    setResetFilters,
  } = useContextValues()

  // Container that needs to scroll
  const scrollContainer = useRef()

  // Hold a master list of all services in geoJson
  const { allServices } = useServices()

  // Get the cleared list of filters
  const clearedFilters = useClearedFilters()

  // Local state to handle the displayed results
  const [displayedResults, setDisplayedResults] = useState(allServices)

  // Get the unmapped results
  const unMappedResults = allResults.filter(
    (service) => service.geometry == null
  )

  // Reset results when page first loads
  useEffect(() => {
    if (!hasQueryFromIndex && !hasPopularFilters) {
      setSearchResults(allServices)
      setAllResults(allServices)
      setFilterValues(clearedFilters)
      setResetFilters(true)
      setHasQuery(false)
    }
  }, []) //eslint-disable-line

  // Run the search query on page load if necessary
  useEffect(() => {
    if (hasQueryFromIndex && !hasPopularFilters && fuseSearchList != null) {
      handleSearch(searchQuery)
      setHasQueryFromIndex(false)
    }
    if (hasQueryFromIndex && hasPopularFilters && fuseSearchList != null) {
      handleSearchWithFilters(searchQuery)
      setHasPopularFilters(false)
      setHasQueryFromIndex(false)
    }
  }, [hasQueryFromIndex, fuseSearchList]) //eslint-disable-line

  // Update the displayed results if the markers change on the map, e.g. zooming
  useEffect(() => {
    setDisplayedResults([...visibleMarkers, ...unMappedResults])
  }, [visibleMarkers]) //eslint-disable-line

  // Update the results list when all results change, e.g. when a search is entered
  useEffect(() => {
    setDisplayedResults([...allResults])
  }, [allResults])

  // Update the scroll position when the results list updates
  useEffect(() => {
    scrollContainer.current &&
      scrollContainer.current.scroll({
        top: 0,
        left: 0,
      })
  }, [displayedResults])

  return (
    <MapLayout>
      <Seo
        title="Map"
        description="A map of all services listed on MindMapBC. You can search and filter the services to find the most relevant results for you. MindMapBC is a custom database of mental health resources focused on LGBTQ2S+ health."
      />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossorigin=""
        />
      </Helmet>
      <div
        sx={{
          height: "100%",
          display: "grid",
          gridTemplateRows: [
            "60px 1fr 200px",
            null,
            "52px calc(100vh - 128px)",
            "52px calc(100vh - 138px)",
            null,
          ],
          gridTemplateColumns: [
            "100vw",
            null,
            "50vw minmax(0, 1fr)",
            "40vw minmax(0, 1fr)",
            "30vw minmax(0, 1fr)",
          ],
        }}
      >
        <div
          sx={{
            gridColumn: ["1 / -1", null, "2 / 3", null, null],
            gridRow: ["1 / 3", null, "2 / -1", null, null],
            height: ["100%", null, "100%", null, null],
            width: "100%",
          }}
        >
          {useHasMounted && <MapLeaflet />}
        </div>
        <div
          sx={{
            mx: 3,
            gridColumn: "1 / -1",
            gridRow: "1 / 2",
            zIndex: "3000",
            alignSelf: "center",
          }}
        >
          <Media at="sm">
            <SearchForm isMap={true} />
          </Media>
        </div>
        <div sx={{ gridColumn: "1 / -1", gridRow: "3 / 4" }}>
          <Media at="sm">
            <MapBottomSheet>
              <MapBottomSheetHeader results={displayedResults}>
                <div sx={{ mx: 3 }}>
                  <SearchActiveFilters isMap />
                </div>
              </MapBottomSheetHeader>
              <div
                ref={scrollContainer}
                sx={{
                  height: "450px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  webkitOverflowScrolling: "touch",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div sx={{ mx: 3, mb: 5, mt: 3 }}>
                  <SearchResults isMap results={displayedResults} />
                </div>
                <Footer isMap={true} />
              </div>
            </MapBottomSheet>
          </Media>
        </div>
        <div sx={{ gridColumn: "1 / -1", gridRow: "1 / 2" }}>
          <Media greaterThanOrEqual="md">
            <MapFilterMenu />
          </Media>
        </div>
        <div
          sx={{
            gridColumn: "1 / 2",
            gridRow: "2 / -1",
            height: "100%",
            "> div": {
              height: "100%",
            },
          }}
        >
          <Media greaterThanOrEqual="md">
            <aside
              sx={{
                height: "100%",
                display: "grid",
                gridTemplateColumns: [null, null, "minmax(0, 1fr)", null, null],
                gridTemplateRows: "auto minmax(0, 1fr)",
              }}
            >
              <div
                sx={{
                  gridColumn: "1 / -1",
                  gridRow: "1 / 2",
                  py: 3,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "muted",
                }}
              >
                <div sx={{ mx: 3 }}>
                  <SearchNumberOfResults isMap results={displayedResults} />
                  <SearchActiveFilters isMap />
                </div>
              </div>
              <div
                ref={scrollContainer}
                sx={{
                  gridColumn: [null, null, "1 / 2", "1 / 2", null],
                  gridRow: "2 / 3",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  webkitOverflowScrolling: "touch",
                }}
              >
                <div sx={{ mx: 3, mt: 3, mb: 5 }}>
                  <SearchResults isMap results={displayedResults} />
                </div>
                <Footer isMap={true} />
              </div>
            </aside>
          </Media>
        </div>
      </div>
      {isFilterOpen && <FilterModal isMap />}
    </MapLayout>
  )
}

export default MapPage
