/** @jsx jsx */
import { jsx, Themed, useThemeUI } from "theme-ui"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaRegClock } from "react-icons/fa"
import ReactRough, { Circle } from "react-rough"
import Seo from "../layout/seo"
import Layout from "../layout/layout"
import SanityContent from "../sanity/sanity-content"
import PostFooter from "./post-footer"

const PostTemplate = ({ data }) => {
  const { previous, next } = data
  const post = data.sanityPost
  const hasSubtitle =
    data.sanityPost.subTitle != null && data.sanityPost.subTitle !== ""
  const { theme } = useThemeUI()
  const accentColor = theme.colors.accent

  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.excerpt}
        image={post.featuredImage}
      />
      <article
        sx={{
          display: "grid",
          gridTemplateRows: [
            "150px auto",
            "300px auto",
            "200px 250px auto",
            "150px 250px auto",
            "250px 250px auto",
          ],
          gridTemplateColumns: "1fr minmax(0px, 768px) 1fr",
          justifyContent: "center",
          width: "100vw",
          position: "relative",
          left: "calc(-50vw + 50%)",
          variant: "variants.postContainer",
        }}
      >
        <GatsbyImage
          image={post.featuredImage.asset.gatsbyImageData}
          sx={{
            gridColumn: "1 / -1",
            gridRow: ["1 / 2", null, "1 / 3", null, null],
            zIndex: 10,
            width: "100%",
            maxWidth: "maxPageWidth",
            mx: "auto",
            variant: "variants.postImage",
          }}
          alt={post.featuredImage.alt}
        />
        <div
          sx={{
            gridColumn: "2 / 3",
            gridRow: ["2 / -1", null, "2 / -1", null, null],
            zIndex: 20,
            bg: "background",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
          }}
        >
          <span
            sx={{
              color: "textGray",
              fontSize: [0, 1, null, null, null],
              ml: 3,
            }}
          >
            {post.featuredImage.caption}
          </span>
          <div
            sx={{
              pt: 3,
              pb: 5,
              px: [3, null, 4, null, null],
            }}
          >
            <div sx={{ py: 3 }}>
              <Themed.h1
                sx={{
                  textAlign: "center",
                  fontSize: [5, 6, null, null, null],
                  mt: 0,
                  mb: 3,
                  variant: "variants.postTitle",
                }}
              >
                {post.title}
              </Themed.h1>
              <div
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <ReactRough renderer="svg" width={72} height={18}>
                  <Circle
                    diameter={16}
                    fill={accentColor}
                    hachureGap={2}
                    x={9}
                    y={9}
                  />
                  <Circle
                    diameter={16}
                    fill={accentColor}
                    hachureGap={2}
                    x={36}
                    y={9}
                  />
                  <Circle
                    diameter={16}
                    fill={accentColor}
                    hachureGap={2}
                    x={63}
                    y={9}
                  />
                </ReactRough>
              </div>
              {hasSubtitle && (
                <Themed.h2
                  sx={{
                    fontStyle: "italic",
                    fontSize: [2, 3, null, null, null],
                    textAlign: "center",
                    mt: 3,
                  }}
                >
                  {post.subTitle}
                </Themed.h2>
              )}
              <Themed.p
                sx={{
                  color: "textGray",
                  fontSize: [0, 1, null, null, null],
                  textAlign: "center",
                  letterSpacing: "wider",
                  mb: 0,
                }}
              >
                {post.author}
              </Themed.p>
            </div>
            <div sx={{ mt: 4 }}>
              <Themed.p
                sx={{
                  color: "textGray",
                  fontSize: [0, 1, null, null, null],
                  letterSpacing: "wider",
                  m: 0,
                  variant: "variants.postMeta",
                }}
              >
                {post.date} &bull;{" "}
                <FaRegClock
                  sx={{
                    position: "relative",
                    top: "0.125em",
                  }}
                />{" "}
                {post.readingTimeInMinutes} Min Read
              </Themed.p>
              <SanityContent data={post._rawBody} />
            </div>
            <PostFooter previous={previous} next={next} />
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query MMBCPostv2($id: String!, $previousId: String, $nextId: String) {
    sanityPost(id: { eq: $id }) {
      id
      title
      subTitle
      author
      date(formatString: "MMMM D, YYYY")
      excerpt
      readingTimeInMinutes
      featuredImage {
        alt
        caption
        asset {
          metadata {
            dimensions {
              height
              width
            }
          }
          url
          gatsbyImageData
        }
      }
      _rawBody
    }
    previous: sanityPost(id: { eq: $previousId }) {
      id
      excerpt
      slug {
        current
      }
      title
      date(formatString: "MMMM DD, YYYY")
    }
    next: sanityPost(id: { eq: $nextId }) {
      id
      excerpt
      slug {
        current
      }
      title
      date(formatString: "MMMM DD, YYYY")
    }
  }
`
