import { useStaticQuery, graphql } from "gatsby"

export const useHomeContent = () => {
  const data = useStaticQuery(graphql`
    query {
      popularCosts: allSanityServiceCost(
        filter: { displayed: { eq: true }, popular: { eq: true } }
      ) {
        nodes {
          name
          slug {
            current
          }
          icon {
            asset {
              url
            }
          }
        }
      }
      popularFeatures: allSanityServiceFeature(
        filter: { displayed: { eq: true }, popular: { eq: true } }
      ) {
        nodes {
          name
          slug {
            current
          }
          icon {
            asset {
              url
            }
          }
        }
      }
      popularFormats: allSanityServiceFormat(
        filter: { displayed: { eq: true }, popular: { eq: true } }
      ) {
        nodes {
          name
          slug {
            current
          }
          icon {
            asset {
              url
            }
          }
        }
      }
      popularCategories: allSanityServiceCategory(
        filter: { displayed: { eq: true }, popular: { eq: true } }
      ) {
        nodes {
          name
          slug {
            current
          }
          icon {
            asset {
              url
            }
          }
        }
      }
      allSanityPost(filter: { featured: { eq: true } }, limit: 3) {
        nodes {
          id
          title
          date(formatString: "MMMM D, YYYY")
          excerpt(limit: 120)
          slug {
            current
          }
        }
      }
      totalCount: allSanityService {
        totalCount
      }
      allSanityService(
        filter: { featured: { eq: true }, displayed: { eq: true } }
        limit: 3
      ) {
        totalCount
        nodes {
          id
          name
          excerpt(limit: 200)
          slug {
            current
          }
          categories {
            name
            slug {
              current
            }
            icon {
              asset {
                url
              }
            }
          }
          features {
            name
            slug {
              current
            }
            icon {
              asset {
                url
              }
            }
          }
          formats {
            name
            slug {
              current
            }
            icon {
              asset {
                url
              }
            }
          }
          costs {
            name
            slug {
              current
            }
            icon {
              asset {
                url
              }
            }
          }
        }
      }
      allSanityHomePage(limit: 1, sort: { fields: _updatedAt, order: DESC }) {
        nodes {
          bannerTitle
          bannerSubTitle
          articleTitle
          crisisTitle
          aboutTitle
          contributeTitle
          _rawCrisisContent
          featuredServicesTitle
          aboutTitle1
          _rawAboutContent1
          aboutTitle2
          _rawAboutContent2
          _rawContributeContent
          partnershipTitle
          bannerImage {
            asset {
              gatsbyImageData
            }
          }
          partnershipLogos {
            link
            alt
            asset {
              id
              gatsbyImageData(height: 150)
            }
          }
          articleImage1 {
            alt
            asset {
              gatsbyImageData(height: 300)
            }
          }
          articleImage2 {
            alt
            asset {
              gatsbyImageData(height: 300)
            }
          }
          articleImage3 {
            alt
            asset {
              gatsbyImageData(height: 300)
            }
          }
          aboutImage1 {
            alt
            caption
            asset {
              gatsbyImageData(height: 800)
            }
          }
          aboutImage2 {
            alt
            caption
            asset {
              gatsbyImageData(height: 800)
            }
          }
          contributeImage {
            alt
            caption
            asset {
              gatsbyImageData(height: 800)
            }
          }
        }
      }
    }
  `)

  const bannerImage =
    data.allSanityHomePage.nodes[0].bannerImage.asset.gatsbyImageData
  const bannerTitle = data.allSanityHomePage.nodes[0].bannerTitle
  const bannerSubTitle = data.allSanityHomePage.nodes[0].bannerSubTitle

  const articleTitle = data.allSanityHomePage.nodes[0].articleTitle

  const article1 = data.allSanityPost.nodes[0]
  const article2 = data.allSanityPost.nodes[1]
  const article3 = data.allSanityPost.nodes[2]

  const articleImage1 = data.allSanityHomePage.nodes[0].articleImage1
  const articleImage2 = data.allSanityHomePage.nodes[0].articleImage2
  const articleImage3 = data.allSanityHomePage.nodes[0].articleImage3

  const featuredArticles = [
    { article: article1, image: articleImage1 },
    { article: article2, image: articleImage2 },
    { article: article3, image: articleImage3 },
  ]

  const crisisTitle = data.allSanityHomePage.nodes[0].crisisTitle
  const crisisContent = data.allSanityHomePage.nodes[0]._rawCrisisContent

  const featuredServices = data.allSanityService.nodes
  const featuredServicesTitle =
    data.allSanityHomePage.nodes[0].featuredServicesTitle

  const numberOfServices = data.totalCount.totalCount

  const aboutTitle = data.allSanityHomePage.nodes[0].aboutTitle

  const aboutTitle1 = data.allSanityHomePage.nodes[0].aboutTitle1
  const aboutContent1 = data.allSanityHomePage.nodes[0]._rawAboutContent1
  const aboutImage1 = data.allSanityHomePage.nodes[0].aboutImage1

  const aboutTitle2 = data.allSanityHomePage.nodes[0].aboutTitle2
  const aboutContent2 = data.allSanityHomePage.nodes[0]._rawAboutContent2
  const aboutImage2 = data.allSanityHomePage.nodes[0].aboutImage2

  const partnershipTitle = data.allSanityHomePage.nodes[0].partnershipTitle
  const partnershipLogos = data.allSanityHomePage.nodes[0].partnershipLogos

  const contributeTitle = data.allSanityHomePage.nodes[0].contributeTitle
  const contributeContent =
    data.allSanityHomePage.nodes[0]._rawContributeContent
  const contributeImage = data.allSanityHomePage.nodes[0].contributeImage

  const popularFilters = {
    features: [...data.popularFeatures.nodes],
    categories: [...data.popularCategories.nodes],
    costs: [...data.popularCosts.nodes],
    formats: [...data.popularFormats.nodes],
  }

  return {
    bannerImage,
    bannerTitle,
    bannerSubTitle,
    articleTitle,
    article1,
    article2,
    article3,
    articleImage1,
    articleImage2,
    articleImage3,
    crisisTitle,
    crisisContent,
    featuredServices,
    featuredServicesTitle,
    numberOfServices,
    aboutTitle,
    aboutTitle1,
    aboutContent1,
    aboutImage1,
    aboutTitle2,
    aboutContent2,
    aboutImage2,
    partnershipLogos,
    partnershipTitle,
    featuredArticles,
    popularFilters,
    contributeTitle,
    contributeContent,
    contributeImage,
  }
}
