/** @jsx jsx */
import { jsx, Themed, useThemeUI } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { useSurveyQuestions } from "../../utils/hooks/use-survey-questions"
import Seo from "../layout/seo"
import Layout from "../layout/layout"
import SanityContent from "../sanity/sanity-content"
import ServiceTemplateHeader from "./service-template-header"
import ServiceTemplateAddress from "./service-template-address"
import ServiceTemplateFooter from "./service-template-footer"
import ServiceTemplateLanguages from "./service-template-languages"
import ServiceTag from "./service-template-tag"

const ServicePageTemplate = ({ data }) => {
  const service = data.sanityService

  const bannerImage =
    data.allSanityServicePageBannerImage.nodes[0].image.asset.gatsbyImageData

  // Check if there are languages
  const hasLanguages = service.languages != null && service.languages.length > 0

  // Check if there is an address
  const hasAddress =
    service.address != null &&
    service.address.address != null &&
    service.address.city != null &&
    service.address.location != null

  // Check if we should display the sidebar
  const hasSidebar = hasLanguages || hasAddress

  // Check if there is a survey
  const hasSurvey = service.displaySurvey

  // Get the tags associated with the service
  const allTags = [
    ...service.categories,
    ...service.costs,
    ...service.features,
    ...service.formats,
  ]

  // Get the max content width
  const { theme } = useThemeUI()
  const maxContentWidth = theme.sizes.maxContentWidth

  const { surveyMetadata } = useSurveyQuestions()

  return (
    <Layout>
      <Seo
        title={service.name}
        description={service.excerpt}
        image={bannerImage}
      />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossorigin=""
        />
      </Helmet>
      <div
        sx={{
          width: "100vw",
          position: "relative",
          left: "calc(-50vw + 50%)",
        }}
      >
        <ServiceTemplateHeader service={service} bannerImage={bannerImage} />
        <div sx={{ bg: "background" }}>
          <div
            sx={{
              pt: [4, null, 5, null, null],
              px: 3,
              display: "grid",
              gridGap: [0, null, 5, null, null],
              justifyContent: ["stretch", null, "center", null, null],
              gridTemplateColumns: hasSidebar
                ? [
                    `minmax(0, ${maxContentWidth})`,
                    null,
                    `300px minmax(0, ${maxContentWidth})`,
                    null,
                    null,
                  ]
                : [`minmax(0, ${maxContentWidth})`, null, null, null, null],
            }}
          >
            <div
              sx={{
                gridColumn: hasSidebar
                  ? ["1 / -1", null, "2 / 3", null, null]
                  : "1 / -1",
              }}
            >
              <Themed.h2
                sx={{
                  fontSize: [2, 3, null, null, null],
                  mt: 0,
                }}
              >
                Summary
              </Themed.h2>
              <ul
                sx={{
                  listStyle: "none",
                  m: 0,
                  p: 0,
                  mb: 3,
                  lineHeight: "tight",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {allTags.map((category) => (
                  <ServiceTag category={category} key={category.slug.current} />
                ))}
              </ul>
              <SanityContent data={service._rawSummary} />
              {hasSurvey ? (
                <div
                  sx={{ bg: "mutedGreen", px: 3, py: 4, borderRadius: "6px" }}
                >
                  <Themed.h2
                    sx={{
                      fontSize: [2, 3, null, null, null],
                      mt: 0,
                    }}
                  >
                    {surveyMetadata.surveyPageTitle}
                  </Themed.h2>
                  <div sx={{ p: { fontSize: 1 } }}>
                    <SanityContent data={surveyMetadata._rawSurveyPageIntro} />
                  </div>
                  <div sx={{ p: { fontSize: 1, color: "textGray" } }}>
                    <SanityContent data={service._rawSurveyResponse} />
                  </div>
                </div>
              ) : null}
            </div>
            {hasSidebar && (
              <div
                sx={{
                  gridColumn: ["1 / -1", null, "1 / 2", null, null],
                  gridRow: ["2 / 3", null, "1 / 2", null, null],
                }}
              >
                {hasAddress && <ServiceTemplateAddress service={service} />}
                {hasLanguages && (
                  <ServiceTemplateLanguages
                    service={service}
                    hasAddress={hasAddress}
                  />
                )}
              </div>
            )}
            <div
              sx={{
                gridColumn: "1/ -1",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ServiceTemplateFooter service={service} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ServicePageTemplate

export const query = graphql`
  query SanityServiceQueryv4($id: String!) {
    sanityService(id: { eq: $id }) {
      _rawSummary
      _rawSurveyResponse
      _updatedAt(formatString: "MMMM D, YYYY")
      _id
      id
      name
      displaySurvey
      excerpt
      slug {
        current
      }
      contact {
        phone
        website
        email
      }
      address {
        address
        apartment
        city
        province
        country
        postal
        location {
          lat
          lng
        }
      }
      categories {
        id
        name
        displayed
        slug {
          current
        }
        icon {
          asset {
            url
          }
        }
      }
      formats {
        id
        name
        displayed
        slug {
          current
        }
        icon {
          asset {
            url
          }
        }
      }
      features {
        id
        name
        displayed
        slug {
          current
        }
        icon {
          asset {
            url
          }
        }
      }
      costs {
        id
        name
        displayed
        slug {
          current
        }
        icon {
          asset {
            url
          }
        }
      }
      languages {
        id
        name
        displayed
        slug {
          current
        }
      }
    }
    allSanityServicePageBannerImage(
      limit: 1
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        image {
          asset {
            metadata {
              dimensions {
                height
                width
              }
            }
            url
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              width: 1920
            )
          }
        }
      }
    }
  }
`
