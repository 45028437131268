/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Seo from "../../../components/layout/seo"
import Layout from "../../../components/layout/layout"
import Card from "../../../components/page-templates/category-template-card"
import PageH1 from "../../../components/page-templates/page-h1"

const CategoryPage = ({ data }) => {
  const { sanityServiceCategory } = data
  const category = sanityServiceCategory.name
  const description = sanityServiceCategory.description
  const services = data.sanityServiceCategory.services
  const sortedServices =
    services != null
      ? services.sort(function (a, b) {
          var nameA = a.properties.name
          var nameB = b.properties.name
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          // names must be equal
          return 0
        })
      : []
  return (
    <Layout>
      <Seo title={category} description={description} />
      <div
        sx={{
          width: "100vw",
          height: "auto",
          left: "calc(-50vw + 50%)",
          position: "relative",
        }}
      >
        <div sx={{ mx: [3, null, 4, null, null], mb: 5 }}>
          <PageH1 sx={{ fontSize: [4, 5, 6, null, null] }}>
            Category: {category}
          </PageH1>
        </div>
        <div
          sx={{
            mx: [3, null, 4, null, null],
            mb: 5,
            display: "grid",
            gridGap: 4,
            gridTemplateColumns: [
              "minmax(0, 1fr)",
              "repeat(auto-fit, minmax(400px, 1fr))",
              null,
              null,
              null,
            ],
          }}
        >
          {sortedServices.map((service) => {
            return <Card service={service} key={service.properties._id} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export default CategoryPage

export const query = graphql`
  query ServiceCategoryQueryv2($id: String!) {
    sanityServiceCategory(id: { eq: $id }) {
      name
      description
      services {
        id
        type
        geometry {
          type
          coordinates
        }
        properties {
          _id
          name
          slug
          summary
          excerpt
          email
          phone
          website
          categories {
            displayed
            name
            icon
            slug
          }
          features {
            displayed
            name
            icon
            slug
          }
          formats {
            displayed
            name
            icon
            slug
          }
          costs {
            displayed
            name
            icon
            slug
          }
          languages {
            displayed
            name
            slug
          }
        }
      }
    }
  }
`
