import { useStaticQuery, graphql } from "gatsby"

export const useSurveyQuestions = () => {
  const data = useStaticQuery(graphql`
    query QuestionQuery {
      allSanitySurveyMetadata(
        limit: 1
        sort: { fields: _updatedAt, order: DESC }
      ) {
        nodes {
          id
          _rawSurveyFormIntro
          _rawSurveyPageIntro
          surveyFormTitle
          surveyPageTitle
          categoryOrder {
            id
            _id
            title
            questions {
              id
              _id
              question
              answers
            }
          }
        }
      }
    }
  `)

  const surveyQuestionCategories =
    data.allSanitySurveyMetadata.nodes[0].categoryOrder

  const surveyMetadata = data.allSanitySurveyMetadata.nodes[0]

  return { surveyQuestionCategories, surveyMetadata }
}
