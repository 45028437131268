/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HomeArticleCard = ({ article, image, ...props }) => {
  const rootPath = `articles/` //Ensure trailing slash

  return (
    <div
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      {...props}
    >
      <div>
        <GatsbyImage
          image={image.asset.gatsbyImageData}
          sx={{ height: ["100px", null, "130px", null, null] }}
          imgStyle={{ objectFit: "contain" }}
          alt={image.alt}
        />
        <Themed.h3 sx={{ fontSize: 3 }}>
          <Themed.a
            sx={{
              color: "text",
              textDecoration: "none",
              ":hover": { textDecoration: "underline", color: "highlight" },
            }}
            as={Link}
            to={rootPath.concat(article.slug.current.replace(/\/*$/, `/`))}
          >
            {article.title}
          </Themed.a>
        </Themed.h3>
        <Themed.p>{article.excerpt}</Themed.p>
      </div>
      <div>
        <Themed.p>
          <Themed.a
            sx={{ color: "text", ":hover": { color: "highlight" } }}
            as={Link}
            to={rootPath.concat(article.slug.current.replace(/\/*$/, `/`))}
          >
            Read more &rarr;
          </Themed.a>
        </Themed.p>
      </div>
    </div>
  )
}

export default HomeArticleCard
