/** @jsx jsx */
import { jsx, Button, Themed } from "theme-ui"
import { useForm } from "react-hook-form"
import { FiAlertOctagon, FiLoader } from "react-icons/fi"
import { useServices } from "../../utils/hooks/use-services"
import { navigate } from "gatsby"

const RequestChangesForm = ({ serviceId }) => {
  ///////////////// SETUP ////////////////////////

  const { allServices } = useServices()

  // Initiate forms
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: { service: serviceId },
  })

  // Transform the formdata into a format that Netlify can handle
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const handleRequest = (formData, event) => {
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "request-changes", ...formData }),
    })
      .then((response) => {
        if (response.status === 200) {
          navigate("/submission-received/")
          reset()
        } else {
          navigate("/submission-error/")
        }
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    event.preventDefault()
  }

  return (
    <form
      onSubmit={handleSubmit(handleRequest)}
      name="request-changes"
      method="POST"
      action="/services/submission-received/"
      data-netlify="true"
      netlify-honeypot="got-ya"
    >
      <input type="hidden" name="form-name" value="request-changes" />
      <input type="hidden" {...register("formId")} value="request-changes" />
      <Themed.h2 sx={{ fontSize: [3, null, 4, null, null] }}>
        Your Information
      </Themed.h2>
      <label htmlFor="submitterName">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.submitterName ? "#ff0000" : "text",
          }}
        >
          Name
        </Themed.p>
        {errors.submitterName && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required
          </Themed.p>
        )}
        <input
          {...register("submitterName", { required: true })}
          sx={{
            fontSize: 1,
            borderColor: errors.submitterName ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <label htmlFor="submitterEmail">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: errors.submitterEmail ? "#ff0000" : "text",
            lineHeight: "tight",
          }}
        >
          Email
        </Themed.p>
        {errors.submitterEmail && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required, please format as email@email.com
          </Themed.p>
        )}
        <input
          {...register("submitterEmail", {
            required: true,
            pattern:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          })}
          sx={{
            fontSize: 1,
            borderColor: errors.submitterEmail ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <label htmlFor="submitterOrg">
        <Themed.p
          sx={{
            fontSize: 1,
            m: 0,
            color: "text",
          }}
        >
          Organization
        </Themed.p>
        <input
          {...register("submitterOrg")}
          sx={{
            fontSize: 1,
            borderColor: "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "300px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        />
      </label>
      <Themed.h2 sx={{ fontSize: [3, null, 4, null, null] }}>
        Change request
      </Themed.h2>
      <label htmlFor="service">
        <Themed.p
          sx={{
            color: errors.service ? "#ff0000" : "text",
            fontSize: 1,
            m: 0,
          }}
        >
          Select service
        </Themed.p>
        {errors.service && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required
          </Themed.p>
        )}
        <select
          {...register("service", { required: true })}
          sx={{
            fontSize: 1,
            borderColor: errors.service ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            maxWidth: "250px",
            width: "100%",
            mt: 1,
            mb: 3,
          }}
        >
          {allServices.map((service) => (
            <option value={service.properties._id} key={service.properties._id}>
              {service.properties.name}
            </option>
          ))}
        </select>
      </label>
      <label htmlFor="changes">
        <Themed.p
          sx={{
            fontSize: 1,
            mt: 0,
            lineHeight: "tight",
            color: errors.changes ? "#ff0000" : "text",
          }}
        >
          Changes Requested
          <br />
          <span
            sx={{
              fontStyle: "italic",
              fontSize: 0,
              color: errors.changes ? "#ff0000" : "textGray",
              lineHeight: "none",
            }}
          >
            Please be detailed and include relevant information needed to update
            the database, e.g. if requesting a changed address please include
            the complete address.
          </span>
        </Themed.p>
        {errors.changes && (
          <Themed.p
            sx={{
              fontSize: 0,
              color: "#ff0000",
              m: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiAlertOctagon /> &nbsp;Required
          </Themed.p>
        )}
        <textarea
          rows="12"
          {...register("changes", { required: true })}
          sx={{
            resize: "none",
            fontSize: 1,
            borderColor: errors.changes ? "#ff0000" : "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            width: "100%",
            mt: 1,
          }}
        />
      </label>
      <label
        htmlFor="got-ya"
        sx={{
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: "1px",
          width: "1px",
          margin: "-1px",
          padding: "0",
          border: "0",
        }}
      >
        Don’t fill this out if you're human:
        <input tabIndex="-1" {...register("got-ya")} />
      </label>
      <div
        sx={{
          display: "flex",
          mt: 4,
          justifyContent: ["center", null, "start", null, null],
        }}
      >
        <Button
          type="submit"
          variant="submit"
          disabled={isSubmitSuccessful ? true : false}
          sx={{
            width: "120px",
            height: "40px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: isSubmitSuccessful ? 0.5 : 1,
            cursor: isSubmitSuccessful ? "wait" : "pointer",
            ":after": { content: isSubmitSuccessful && "''" },
          }}
        >
          {isSubmitSuccessful ? (
            <FiLoader
              sx={{
                fontSize: "3",
                animation: "spin infinite 5s linear",
                "@keyframes spin": {
                  from: {
                    transform: "rotate(0deg)",
                  },
                  to: {
                    transform: "rotate(360deg)",
                  },
                },
              }}
            />
          ) : (
            `Submit`
          )}
        </Button>
      </div>
    </form>
  )
}

export default RequestChangesForm
