/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import AddServiceForm from "../components/forms/add-service-form"
import PageH1 from "../components/page-templates/page-h1"
import { useStaticQuery, graphql } from "gatsby"
import SanityContent from "../components/sanity/sanity-content"

const AddAService = () => {
  const data = useStaticQuery(graphql`
    {
      text: allSanityAddAServiceText(
        limit: 1
        sort: { fields: _updatedAt, order: DESC }
      ) {
        nodes {
          _rawBody
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Add a service"
        description="Thank-you for taking the time to submit a service to the MindMapBC database! We appreciate your help more than you know - you are a superstar! All submissions are reviewed by our team for accuracy and completion before being added to the database. MindMapBC is a custom database of mental health resources focused on LGBTQ2S+ health."
      />
      <PageH1>Add a service</PageH1>
      <SanityContent data={data.text.nodes[0]._rawBody} />
      <div sx={{ mb: 5 }}>
        <AddServiceForm />
      </div>
    </Layout>
  )
}

export default AddAService
