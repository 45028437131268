/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import { FiPhone, FiExternalLink } from "react-icons/fi"
import ServiceTag from "../search/service-tag"

const CategoryTemplateCard = ({ service }) => {
  const servicePath = service.properties.slug.replace(/\/*$/, `/`)
  const hasPhone = service.properties.phone !== null
  const hasWebsite = service.properties.website !== null
  const allTags = [
    ...service.properties.categories,
    ...service.properties.costs,
    ...service.properties.features,
    ...service.properties.formats,
  ]

  return (
    <div
      sx={{
        bg: "muted",
        borderRadius: "6px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        position: "relative",
        top: "0",
        height: "100%",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <div
            sx={{
              bg: "accent",
              text: "white",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
              px: 3,
              pt: 3,
              pb: 2,
            }}
          >
            <Themed.h2 sx={{ fontSize: [2, null, 3, null, null], mt: 0 }}>
              <Themed.a
                as={Link}
                to={`/services/${servicePath}`}
                sx={{
                  color: "white",
                  ":hover": {
                    color: "mutedPurple",
                  },
                }}
              >
                {service.properties.name}
              </Themed.a>
            </Themed.h2>
            <ul
              sx={{
                listStyle: "none",
                m: 0,
                p: 0,
                lineHeight: "tight",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {allTags.map((category) => (
                <ServiceTag
                  key={category.slug}
                  category={category}
                  sx={{
                    color: "white",
                    img: {
                      filter: "invert(1)",
                    },
                    a: {
                      color: "white",
                      ":hover": {
                        color: "mutedPurple",
                      },
                    },
                  }}
                />
              ))}
            </ul>
          </div>
          <div sx={{ px: 3 }}>
            <Themed.p
              sx={{ overflow: "hidden", fontSize: [1, null, 2, null, null] }}
            >
              {service.properties.excerpt}
            </Themed.p>
          </div>
        </div>
        <div sx={{ px: 3, pb: 3 }}>
          <ul
            sx={{
              display: "flex",
              m: 0,
              p: 0,

              listStyle: "none",
              fontSize: 2,
            }}
          >
            {hasPhone && (
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: [2, 3, null, null, null],
                }}
              >
                <Themed.a
                  href={`tel:${service.properties.phone}`}
                  sx={{
                    color: "text",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    fontSize: [0, null, 1, null, null],
                    px: 3,
                    py: 1,
                    borderRadius: "9999em",
                    borderColor: "text",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    transition: "all 0.3s",
                    ":hover, :active, :focus": {
                      color: "text",
                      bg: "mutedPurple",
                    },
                  }}
                >
                  <FiPhone sx={{ mr: 1 }} />
                  {service.properties.phone}
                </Themed.a>
              </li>
            )}
            {hasWebsite && (
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Themed.a
                  href={service.properties.website}
                  target="_blank"
                  rel="noreferrer noopener"
                  sx={{
                    color: "text",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    fontSize: [0, null, 1, null, null],
                    px: 3,
                    py: 1,
                    borderRadius: "9999em",
                    borderColor: "text",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    transition: "all 0.3s",
                    ":hover, :active, :focus": {
                      color: "text",
                      bg: "mutedPurple",
                    },
                  }}
                >
                  <FiExternalLink sx={{ mr: 1 }} />
                  Website
                </Themed.a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CategoryTemplateCard
