// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-templates-post-template-js": () => import("./../../../src/components/page-templates/post-template.js" /* webpackChunkName: "component---src-components-page-templates-post-template-js" */),
  "component---src-components-service-page-service-template-js": () => import("./../../../src/components/service-page/service-template.js" /* webpackChunkName: "component---src-components-service-page-service-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-a-service-js": () => import("./../../../src/pages/add-a-service.js" /* webpackChunkName: "component---src-pages-add-a-service-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-crisis-js": () => import("./../../../src/pages/crisis.js" /* webpackChunkName: "component---src-pages-crisis-js" */),
  "component---src-pages-error-form-for-netlify-js": () => import("./../../../src/pages/error-form-for-netlify.js" /* webpackChunkName: "component---src-pages-error-form-for-netlify-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-request-changes-js": () => import("./../../../src/pages/request-changes.js" /* webpackChunkName: "component---src-pages-request-changes-js" */),
  "component---src-pages-sanity-page-slug-current-js": () => import("./../../../src/pages/{SanityPage.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-js" */),
  "component---src-pages-services-categories-sanity-service-category-slug-current-js": () => import("./../../../src/pages/services/categories/{SanityServiceCategory.slug__current}.js" /* webpackChunkName: "component---src-pages-services-categories-sanity-service-category-slug-current-js" */),
  "component---src-pages-services-categories-sanity-service-cost-slug-current-js": () => import("./../../../src/pages/services/categories/{SanityServiceCost.slug__current}.js" /* webpackChunkName: "component---src-pages-services-categories-sanity-service-cost-slug-current-js" */),
  "component---src-pages-services-categories-sanity-service-feature-slug-current-js": () => import("./../../../src/pages/services/categories/{SanityServiceFeature.slug__current}.js" /* webpackChunkName: "component---src-pages-services-categories-sanity-service-feature-slug-current-js" */),
  "component---src-pages-services-categories-sanity-service-format-slug-current-js": () => import("./../../../src/pages/services/categories/{SanityServiceFormat.slug__current}.js" /* webpackChunkName: "component---src-pages-services-categories-sanity-service-format-slug-current-js" */),
  "component---src-pages-services-categories-sanity-service-language-slug-current-js": () => import("./../../../src/pages/services/categories/{SanityServiceLanguage.slug__current}.js" /* webpackChunkName: "component---src-pages-services-categories-sanity-service-language-slug-current-js" */)
}

