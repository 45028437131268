/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { useEffect } from "react"
import { useForm, useWatch } from "react-hook-form"
import Checkbox from "../forms/checkbox-filter"
import { useCategories } from "../../utils/hooks/use-categories"
import { useClearedFilters } from "../../utils/hooks/use-cleared-filters"
import { useFilter } from "../../utils/hooks/use-filter"
import { useContextValues } from "../../utils/hooks/use-context-values"

const FilterList = () => {
  // Get state and setState
  const {
    filterValues,
    hasPopularFilters,
    resetFilters,
    setResetFilters,
    searchQuery,
    fuseFilterList,
  } = useContextValues()

  // Get filter function
  const { handleFilter } = useFilter()

  // Get the cleared form
  const clearedFilters = useClearedFilters()

  //Get the category info
  const {
    serviceCategories,
    serviceFeatures,
    serviceLanguages,
    serviceCosts,
    serviceFormats,
  } = useCategories()

  // Initiate forms
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: filterValues,
  })

  // Get the watched results
  const watchedResults = useWatch({ control })

  // Conditionally run the search when the search results update
  useEffect(() => {
    const hasResults =
      Object.keys(watchedResults).length > 0 &&
      watchedResults.constructor === Object

    if (fuseFilterList != null && !hasPopularFilters && hasResults) {
      handleFilter(watchedResults)
    }
  }, [watchedResults, hasPopularFilters, fuseFilterList]) //eslint-disable-line

  // Reset the filters if the search result is cleared or changed
  useEffect(() => {
    if (resetFilters) {
      reset(clearedFilters)
      setResetFilters(false)
    }
  }, [resetFilters]) //eslint-disable-line

  // Reset the results to be the telhealth filters on load, if display telehealth is true, only effects non-modal loads of filter form
  useEffect(() => {
    if (hasPopularFilters) {
      const filters = {
        languages: {},
        features: searchQuery.popularFilters.features
          ? { ...searchQuery.popularFilters.features }
          : {},
        formats: searchQuery.popularFilters.formats
          ? { ...searchQuery.popularFilters.formats }
          : {},
        categories: searchQuery.popularFilters.categories
          ? { ...searchQuery.popularFilters.categories }
          : {},
        costs: searchQuery.popularFilters.costs
          ? { ...searchQuery.popularFilters.costs }
          : {},
      }
      reset({ ...filters })
    }
  }, []) //eslint-disable-line

  return (
    <form onSubmit={handleSubmit(handleFilter)}>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <Themed.h3
            sx={{
              fontSize: 2,
              display: "flex",
              alignItems: "center",
              mt: 0,
              "::after": {
                content: '""',
                flex: "1",
                height: "1px",
                backgroundColor: "muted",
                ml: 2,
                mt: "4px",
                display: "inline",
              },
            }}
          >
            Costs
          </Themed.h3>
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              my: 3,
            }}
          >
            {serviceCosts.map((cost) => (
              <Checkbox
                checkbox={cost}
                register={register}
                key={cost.id}
                prefix="costs"
              />
            ))}
          </div>
        </div>
        <div>
          <Themed.h3
            sx={{
              fontSize: 2,
              display: "flex",
              alignItems: "center",
              "::after": {
                content: '""',
                flex: "1",
                height: "1px",
                backgroundColor: "muted",
                ml: 2,
                mt: "4px",
                display: "inline",
              },
            }}
          >
            Features
          </Themed.h3>
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              my: 3,
            }}
          >
            {serviceFeatures.map((cost) => (
              <Checkbox
                checkbox={cost}
                register={register}
                key={cost.id}
                prefix="features"
              />
            ))}
          </div>
        </div>
        <div>
          <Themed.h3
            sx={{
              fontSize: 2,
              display: "flex",
              alignItems: "center",
              "::after": {
                content: '""',
                flex: "1",
                height: "1px",
                backgroundColor: "muted",
                ml: 2,
                mt: "4px",
                display: "inline",
              },
            }}
          >
            Service types
          </Themed.h3>
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              my: 3,
            }}
          >
            {serviceCategories.map((category) => (
              <Checkbox
                prefix="categories"
                checkbox={category}
                register={register}
                key={category.id}
              />
            ))}
          </div>
        </div>

        <div>
          <Themed.h3
            sx={{
              fontSize: 2,
              display: "flex",
              alignItems: "center",
              "::after": {
                content: '""',
                flex: "1",
                height: "1px",
                backgroundColor: "muted",
                ml: 2,
                mt: "4px",
                display: "inline",
              },
            }}
          >
            Formats
          </Themed.h3>
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              my: 3,
            }}
          >
            {serviceFormats.map((category) => (
              <Checkbox
                prefix="formats"
                checkbox={category}
                register={register}
                key={category.id}
              />
            ))}
          </div>
        </div>
      </div>

      <div>
        <Themed.h3
          sx={{
            fontSize: 2,
            display: "flex",
            alignItems: "center",
            "::after": {
              content: '""',
              flex: "1",
              height: "1px",
              backgroundColor: "muted",
              ml: 2,
              mt: "4px",
              display: "inline",
            },
          }}
        >
          Languages
        </Themed.h3>
        <div
          sx={{
            my: 3,
            display: "grid",
            gridColumnGap: 3,
            gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
          }}
        >
          {serviceLanguages.map((language) => (
            <Checkbox
              checkbox={language}
              register={register}
              key={language.id}
              prefix="languages"
            />
          ))}
        </div>
      </div>
    </form>
  )
}

export default FilterList
