/** @jsx jsx */
import { jsx, Themed, Button } from "theme-ui"
import { useForm } from "react-hook-form"
import Checkbox from "./checkbox-error"
import { FiLoader } from "react-icons/fi"
import { navigate } from "gatsby"

const ErrorForm = ({ setIsSuccessful, service }) => {
  const serviceName = service ? service.name : "Placeholder Name"
  const serviceId = service ? service._id : "Placeholder Id"

  // Initiate forms
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm()

  // Transforms the form data from the React Hook Form output to a format Netlify can read
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  // Handles the post process to Netlify so we can access their serverless functions
  const handlePost = (formData, event) => {
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "error-form", ...formData }),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsSuccessful(true)
          reset()
        } else {
          navigate("/submission-error/")
        }
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    event.preventDefault()
  }

  return (
    <form
      onSubmit={handleSubmit(handlePost)}
      name="error-form"
      method="POST"
      data-netlify="true"
      netlify-honeypot="got-ya"
      sx={{ maxWidth: "720px" }}
    >
      <input type="hidden" name="form-name" value="error-form" />
      <input type="hidden" {...register("serviceId")} value={serviceId} />
      <input type="hidden" {...register("serviceName")} value={serviceName} />
      <input type="hidden" {...register("formId")} value="error-form" />
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: [
            "minmax(0, 1fr)",
            "minmax(0, 1fr) minmax(0, 1fr)",
            null,
            null,
            null,
          ],
          gridGap: [2, 3, null, null, null],
        }}
      >
        <Checkbox
          slug="wrongAddress"
          label="Wrong address"
          register={register}
        />
        <Checkbox
          slug="wrongPhone"
          label="Wrong phone Number"
          register={register}
        />
        <Checkbox slug="wrongEmail" label="Wrong email" register={register} />
        <Checkbox
          slug="wrongWebsite"
          label="Wrong website"
          register={register}
        />
        <Checkbox
          slug="wrongSummary"
          label="Incorrect information"
          register={register}
        />
        <Checkbox slug="brokenLink" label="Broken Link" register={register} />
      </div>
      <label htmlFor="text">
        <Themed.p
          sx={{
            fontWeight: "bold",
            mb: 0,
            fontSize: [1, 2, null, null, null],
          }}
        >
          Want to tell us a bit more?
        </Themed.p>
        <textarea
          rows="4"
          {...register("text")}
          sx={{
            resize: "none",
            fontSize: 1,
            borderColor: "textGray",
            borderRadius: "6px",
            borderWidth: "2px",
            borderStyle: "solid",
            p: 1,
            width: "100%",
          }}
        />
      </label>
      <label
        htmlFor="got-ya"
        sx={{
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: "1px",
          width: "1px",
          margin: "-1px",
          padding: "0",
          border: "0",
        }}
      >
        Don’t fill this out if you're human:
        <input tabIndex="-1" {...register("got-ya")} />
      </label>
      <div sx={{ mt: [2, 3, null, null, null] }}>
        <Button
          type="submit"
          variant="submit"
          disabled={isSubmitSuccessful ? true : false}
          sx={{
            fontSize: [1, 2, null, null, null],
            width: "120px",
            height: "40px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: isSubmitSuccessful ? 0.5 : 1,
            cursor: isSubmitSuccessful ? "wait" : "pointer",
            ":after": { content: isSubmitSuccessful && "''" },
          }}
        >
          {isSubmitSuccessful ? (
            <FiLoader
              sx={{
                fontSize: "3",
                animation: "spin infinite 5s linear",
                "@keyframes spin": {
                  from: {
                    transform: "rotate(0deg)",
                  },
                  to: {
                    transform: "rotate(360deg)",
                  },
                },
              }}
            />
          ) : (
            `Submit`
          )}
        </Button>
      </div>
    </form>
  )
}

export default ErrorForm
