/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import PageH1 from "../components/page-templates/page-h1"

const FourOhFour = () => {
  return (
    <Layout>
      <Seo
        title="404"
        description="Broken or missing link. Oh no what has happened! The computers...they have malfunctioned! We are sorry for the inconvenience. Try returning to our homepage. MindMapBC is a custom database of mental health resources focused on LGBTQ2S+ health."
      />
      <PageH1>404</PageH1>
      <div sx={{ mb: 5 }}>
        <Themed.p>
          Oh no! What has happened! The computers...they have malfunctioned!
        </Themed.p>
        <Themed.p>
          You landed on a page that does not exist - but fear not for help is
          not far away. Try browsing the{" "}
          <Themed.a as={Link} to="/map/">
            map of services
          </Themed.a>{" "}
          or the{" "}
          <Themed.a as={Link} to="/list/">
            list of services
          </Themed.a>
          .
        </Themed.p>
      </div>
    </Layout>
  )
}

export default FourOhFour
