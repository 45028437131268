/** @jsx jsx */
import { jsx } from "theme-ui"
import { useContext, useRef, useEffect } from "react"
import { NavContext } from "../../../../utils/context/nav-context"
import { darken } from "@theme-ui/color"
import { motion, AnimatePresence } from "framer-motion"

const NavUlDropdown = ({
  children,
  activeDropdown,
  setActiveDropdown,
  link,
}) => {
  const [isNavOpen] = useContext(NavContext)
  const dropdownRef = useRef(null)
  const isActive = activeDropdown === link

  //Hand clicks outside of the button/ul
  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setActiveDropdown(null)
      }
    }

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent)
    }

    return () => {
      window.removeEventListener("click", pageClickEvent)
    }
  }, [isActive]) //eslint-disable-line

  return (
    <AnimatePresence>
      <motion.ul
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        ref={dropdownRef}
        sx={{
          position: isNavOpen ? "static" : "absolute",
          m: 0,
          pb: 0,
          pt: isNavOpen ? 1 : 3,
          px: 3,
          listStyle: "none",
          backgroundColor: isNavOpen
            ? "header.backgroundOpen"
            : darken("background", 0.08),
          minWidth: "8rem",
          zIndex: "8000",
          boxShadow: ["none", null, "lg", null, null],
        }}
        aria-label="Submenu"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          sx={{
            display: ["none", null, "block", null, null],
            position: "absolute",
            top: "-6px",
            left: "24px",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "0 8px 10px 8px",
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderLeftColor: "transparent",
            borderBottomColor: darken("background", 0.08),
            zIndex: "10000",
          }}
        />
        {children}
      </motion.ul>
    </AnimatePresence>
  )
}

export default NavUlDropdown
