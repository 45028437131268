/** @jsx jsx */
import { useEffect } from "react"
import { jsx, Themed } from "theme-ui"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import { Helmet } from "react-helmet"
import { useServices } from "../utils/hooks/use-services"
import { useClearedFilters } from "../utils/hooks/use-cleared-filters"
import { Media } from "../utils/media"
import SearchForm from "../components/search/search-form"
import SearchResults from "../components/search/search-results"
import FilterModal from "../components/filter/filter-modal"
import FilterList from "../components/filter/filter-list"
import SearchActiveFilters from "../components/search/search-active-filters"
import SearchNumberOfResults from "../components/search/search-number-of-results"
import PageH1 from "../components/page-templates/page-h1"
import { useSearch } from "../utils/hooks/use-search"
import { useContextValues } from "../utils/hooks/use-context-values"
import { mediaStyles } from "../utils/media"

const ListPage = () => {
  // Get search functions
  const { handleSearch, handleSearchWithFilters } = useSearch()

  // Get state and setState
  const {
    searchQuery,
    fuseSearchList,
    hasPopularFilters,
    setHasPopularFilters,
    setSearchResults,
    setAllResults,
    isFilterOpen,
    setHasQuery,
    setFilterValues,
    allResults,
    hasQueryFromIndex,
    setHasQueryFromIndex,
    setResetFilters,
  } = useContextValues()

  // Hold a master list of all services in geoJson
  const { allServices } = useServices()

  // Get the cleared list of filters
  const clearedFilters = useClearedFilters()

  // Reset results when page first loads
  useEffect(() => {
    if (!hasQueryFromIndex && !hasPopularFilters) {
      setSearchResults(allServices)
      setAllResults(allServices)
      setFilterValues(clearedFilters)
      setResetFilters(true)
      setHasQuery(false)
    }
  }, []) //eslint-disable-line

  // Run the search query on page load if necessary
  useEffect(() => {
    if (hasQueryFromIndex && !hasPopularFilters && fuseSearchList != null) {
      handleSearch(searchQuery)
      setHasQueryFromIndex(false)
    }
    if (hasQueryFromIndex && hasPopularFilters && fuseSearchList != null) {
      handleSearchWithFilters(searchQuery)
      setHasPopularFilters(false)
      setHasQueryFromIndex(false)
    }
  }, [hasQueryFromIndex, fuseSearchList]) //eslint-disable-line

  return (
    <Layout>
      <Helmet>
        <style>{mediaStyles}</style>
      </Helmet>
      <Seo
        title="List"
        description="A list of all services listed on MindMapBC. You can search and filter the services to find the most relevant results for you. MindMapBC is a custom database of mental health resources focused on LGBTQ2S+ health."
      />
      <div
        sx={{
          width: "100vw",
          position: "relative",
          left: "calc(-50vw + 50%)",
        }}
      >
        <div
          sx={{
            display: "grid",
            mb: 5,
            gridGap: 4,
            gridTemplateColumns: [
              "minmax(0, 1fr)",
              null,
              "40vw minmax(0, 1fr)",
              "30vw minmax(0, 1fr)",
              "30vw minmax(0, 1fr)",
            ],
          }}
        >
          <div
            sx={{
              gridColumn: ["1 / -1", null, null, null],
              gridRow: "1 / 2",
              mx: [3, null, "auto", null, null],
              width: ["auto", null, "600px", "700px", "800px"],
            }}
          >
            <PageH1 sx={{ textAlign: "center", mt: 4, mb: 5 }}>List</PageH1>
            <SearchForm />
            <SearchNumberOfResults results={allResults} />
            <SearchActiveFilters />
          </div>
          <Media greaterThanOrEqual="md">
            <aside sx={{ ml: 3 }}>
              <Themed.h2 sx={{ mb: 3, fontSize: 4, mt: 0 }}>Filters</Themed.h2>
              <FilterList />
            </aside>
          </Media>
          <div
            sx={{
              gridColumn: ["1 / 2", null, "2 / 3", null, null],
              gridRow: "2 / 3",
              mx: 3,
            }}
          >
            <Themed.h2 sx={{ mb: 4, fontSize: 4, mt: 0 }}>
              Search Results
            </Themed.h2>
            <SearchResults results={allResults} />
          </div>
        </div>
      </div>
      {isFilterOpen && <FilterModal />}
    </Layout>
  )
}

export default ListPage
