/** @jsx jsx */
import { jsx, Button } from "theme-ui"
import { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SanityContent from "../sanity/sanity-content"
import { FiAlertTriangle } from "react-icons/fi"
import { lighten, darken } from "@theme-ui/color"
import { AlertContext } from "../../utils/context/alert-context"
import { FiX } from "react-icons/fi"

const AlertBanner = ({ isMap }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityAlertBanner(
        limit: 1
        sort: { fields: _updatedAt, order: DESC }
      ) {
        nodes {
          _rawBody
        }
      }
    }
  `)
  const alertText = data.allSanityAlertBanner.nodes[0]._rawBody
  const [displayAlert, setDisplayAlert] = useContext(AlertContext) // eslint-disable-line

  const handleClose = () => {
    setDisplayAlert(false)
  }
  if (!displayAlert) {
    return null
  } else {
    return (
      <div
        role="alertdialog"
        aria-label="Sitewide alert"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bg: "alert",
          color: "accent",
          pl: 3,
          pr: 1,
          py: [0, 1, 2, null, null],
        }}
      >
        <div sx={{ display: "flex", alignItems: "center" }}>
          <FiAlertTriangle
            sx={{
              mr: 2,
              flexShrink: "0",
              fontSize: isMap ? 2 : [2, null, 3, null, null],
            }}
          />
          <div
            sx={{
              lineHeight: "tight",
              mx: 3,
              p: { my: 2, fontSize: isMap ? 1 : [1, null, 2, null, null] },
              a: {
                color: "alertLink",
                ":hover": { color: lighten("alertLink", 0.15) },
              },
            }}
          >
            <SanityContent data={alertText} />
          </div>
        </div>
        <Button
          onClick={handleClose}
          aria-label="Close alert"
          sx={{
            alignSelf: "flex-start",
            flexShrink: "0",
            bg: "transparent",
            color: "accent",
            fontSize: 2,
            borderWidth: "0px",
            borderColor: darken("alert", 0.1),
            borderStyle: "none",
            borderRadius: "9999em",
            p: 0,
            mx: 1,
            width: "28px",
            height: "28px",
            display: "grid",
            placeItems: "center",
            boxShadow: "none",
            ":hover, :active, :focus": {
              bg: lighten("alert", 0.1),
              borderWidth: "1px",
              borderColor: darken("alert", 0.1),
              borderStyle: "solid",
            },
          }}
        >
          <FiX />
        </Button>
      </div>
    )
  }
}

export default AlertBanner
