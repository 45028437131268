/** @jsx jsx */
import { jsx, Button, Themed } from "theme-ui"
import { useForm } from "react-hook-form"
import { FiSearch, FiMap, FiList } from "react-icons/fi"
import { navigate } from "gatsby"
import Checkbox from "../forms/checkbox-banner"
import { useContextValues } from "../../utils/hooks/use-context-values"
import { useHomeContent } from "../../utils/hooks/use-home-content"

const HomeSearch = () => {
  // Get the popular filters
  const { popularFilters } = useHomeContent()
  // Initiate forms
  const { register, handleSubmit } = useForm()
  // Get state and set State
  const { setSearchQuery, setHasPopularFilters, setHasQueryFromIndex } =
    useContextValues()

  // Function to check if there are any true values
  const checkIfTrue = (object) => {
    const value = Object.keys(object).some((key) => object[key])
    return value
  }

  // Check to see if there are any active popular filters
  const handleCheckFilters = (formData) => {
    const value =
      (formData.popularFilters.costs &&
        checkIfTrue(formData.popularFilters.costs)) ||
      (formData.popularFilters.categories &&
        checkIfTrue(formData.popularFilters.categories)) ||
      (formData.popularFilters.formats &&
        checkIfTrue(formData.popularFilters.formats)) ||
      (formData.popularFilters.features &&
        checkIfTrue(formData.popularFilters.features))
    return value
  }

  // Function when map button is submitted
  const onSubmitMap = handleSubmit((data) => {
    data.submitAction = "map"
    handleForm(data)
  })

  // Function when list button is submitted
  const onSubmitList = handleSubmit((data) => {
    data.submitAction = "list"
    handleForm(data)
  })

  // Handle submission of the search button
  const handleForm = (formData) => {
    const hasPopularFilters = handleCheckFilters(formData)
    setHasPopularFilters(hasPopularFilters)
    if (formData.searchQuery !== "" || hasPopularFilters === true) {
      setHasQueryFromIndex(true)
      setSearchQuery(formData)
    }
    if (formData.submitAction === "map") {
      navigate("/map/")
    }
    if (formData.submitAction === "list") {
      navigate("/list/")
    }
  }

  return (
    <form sx={{ width: "100%" }} action="">
      <div
        sx={{
          mb: 3,
          display: "grid",
          justifyItems: ["stretch", "center", null, null, null],
        }}
      >
        <label htmlFor="searchQuery">
          <Themed.p
            sx={{
              fontSize: [0, 1, null, null, null],
              m: 0,
              ml: 3,
              textTransform: "uppercase",
              fontWeight: "bold",
              letterSpacing: "wide",
            }}
          >
            Search
          </Themed.p>
          <div
            sx={{
              bg: "background",
              display: "flex",
              alignItems: "center",
              borderRadius: "9999em",
              borderStyle: "solid",
              borderWidth: "4px",
              borderColor: "accent",
              color: "textGray",
              py: [0, 1, null, null, null],
              px: 3,
              width: ["100%", "400px", "600px", null, null],
              boxShadow: "lg",
              ":focus-within": {
                boxShadow: "0px 0px 4px 2px #0066ff",
              },
              /* clears the ‘X’ from Internet Explorer and chroms*/
              "input[type=search]::-ms-clear": {
                display: "none",
                width: 0,
                height: 0,
              },
              "input[type=search]::-ms-reveal": {
                display: "none",
                width: 0,
                height: 0,
              },
              "input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration":
                {
                  display: "none",
                },
            }}
          >
            <FiSearch sx={{ fontSize: [2, null, null, 3, null] }} />
            <input
              {...register("searchQuery")}
              type="search"
              size={15}
              placeholder="City, features, service type..."
              sx={{
                fontSize: [2, null, null, 3, null],
                width: "100%",
                height: "100%",
                border: "none",
                bg: "background",
                ":focus": {
                  outlineStyle: "none",
                  boxShadow: "none",
                  borderColor: "transparent",
                },
              }}
            />
          </div>
        </label>
      </div>
      <Themed.p
        sx={{
          fontSize: [0, 1, null, null, null],
          fontWeight: "bold",
          textAlign: "center",
          letterSpacing: "wide",
          mb: 2,
          textTransform: "uppercase",
        }}
      >
        Select popular filters
      </Themed.p>
      <div
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {popularFilters.features.length > 0 &&
          popularFilters.features.map((filter) => (
            <Checkbox
              key={filter.slug.current}
              register={register}
              label={filter.name}
              slug={filter.slug.current}
              category="features"
            />
          ))}
        {popularFilters.categories.length > 0 &&
          popularFilters.categories.map((filter) => (
            <Checkbox
              key={filter.slug.current}
              register={register}
              label={filter.name}
              slug={filter.slug.current}
              category="categories"
            />
          ))}
        {popularFilters.costs.length > 0 &&
          popularFilters.costs.map((filter) => (
            <Checkbox
              key={filter.slug.current}
              register={register}
              label={filter.name}
              slug={filter.slug.current}
              category="costs"
            />
          ))}
        {popularFilters.formats.length > 0 &&
          popularFilters.formats.map((filter) => (
            <Checkbox
              key={filter.slug.current}
              register={register}
              label={filter.name}
              slug={filter.slug.current}
              category="formats"
            />
          ))}
      </div>
      <div sx={{ display: "flex", justifyContent: "center" }}>
        <div sx={{ mt: 4 }}>
          <Button
            onClick={onSubmitMap}
            type="submit"
            variant="homeCta"
            sx={{ mr: [3, null, 4, null, null], boxShadow: "lg" }}
          >
            <span>
              <FiMap sx={{ mb: "-2px", mr: 1 }} /> Search Map
            </span>
          </Button>
          <Button
            onClick={onSubmitList}
            type="submit"
            variant="homeCta"
            sx={{ boxShadow: "lg" }}
          >
            <span>
              <FiList sx={{ mb: "-2px", mr: 1 }} /> Search List
            </span>
          </Button>
        </div>
      </div>
    </form>
  )
}

export default HomeSearch
