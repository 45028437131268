/** @jsx jsx */
import { jsx, Button } from "theme-ui"
import { MdExpandMore, MdExpandLess } from "react-icons/md"

const NavLinkDropDown = ({
  link,
  children,
  activeDropdown,
  setActiveDropdown,
}) => {
  // const [isNavOpen, setIsNavOpen] = useContext(NavContext) // eslint-disable-line

  const isActive = activeDropdown === link

  const handleClick = () => {
    if (isActive) {
      setActiveDropdown(null)
    } else {
      setActiveDropdown(link)
    }
  }

  return (
    <Button
      variant="none"
      onClick={handleClick}
      aria-haspopup="true"
      aria-expanded={isActive ? "true" : "false"}
      sx={{
        cursor: "pointer",
        fontWeight: "bold",
        border: "none",
        borderRadius: "0",
        boxShadow: "none",
        py: 0,
        px: 1,
        backgroundColor: "transparent",
        color: isActive ? "primary" : "header.text",
        position: "relative",
        letterSpacing: "wide",
        ":focus": {
          outlineStyle: "none",
          boxShadow: "none",
          borderColor: "transparent",
        },
        "::after": {
          position: "absolute",
          borderRadius: "9999em",
          top: "100%",
          left: "0",
          width: "100%",
          height: "1px",
          backgroundColor: "primary",
          content: "''",
          opacity: "0",
          transition: "height 0.3s, opacity 0.3s, transform 0.3s",
          transform: "translateY(-6px)",
        },
        ":hover, :focus, :active": {
          textDecoration: "none",
          color: "primary",
        },
        ":hover::after, :active::after, :focus::after": {
          height: "4px",
          opacity: "1",
          transform: "translateY(0px)",
        },
        variant: "variants.navLinksDropdown",
      }}
    >
      {children}
      {isActive ? (
        <MdExpandLess sx={{ mb: -1 }} />
      ) : (
        <MdExpandMore sx={{ mb: -1 }} />
      )}
    </Button>
  )
}

export default NavLinkDropDown
