/** @jsx jsx */
import { jsx, Themed, Button, useThemeUI } from "theme-ui"
import { Link } from "gatsby"
import { useHomeContent } from "../../utils/hooks/use-home-content"
import SanityContent from "../sanity/sanity-content"
import ReactRough, { Circle } from "react-rough"

const HomeCrisis = () => {
  const { crisisContent, crisisTitle } = useHomeContent()
  const { theme } = useThemeUI()
  const accentColor = theme.colors.accent

  return (
    <section sx={{ position: "relative" }}>
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          mx: "auto",
        }}
      >
        <Themed.h2 sx={{ mb: 2, mx: 3 }}>{crisisTitle}</Themed.h2>
        <div
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <ReactRough renderer="svg" width={72} height={18}>
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={9}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={36}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={63}
              y={9}
            />
          </ReactRough>
        </div>
        <SanityContent data={crisisContent} />
        <Button as={Link} to="/crisis/" variant="homeCta" sx={{ mt: 4 }}>
          View all crisis services
        </Button>
      </div>
    </section>
  )
}

export default HomeCrisis
