/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import { Fragment } from "react"

const ServiceTag = ({ category, ...props }) => {
  const hasIcon = category.icon != null && category.icon !== ""

  return (
    <li
      sx={{
        display: "flex",
        alignItems: "center",
        ":after": {
          content: "'\\002C \\00A0'",
        },
        ":last-of-type:after": { content: "''" },
      }}
      {...props}
    >
      {hasIcon && (
        <Fragment>
          <img src={category.icon} alt={category.name} height="18" width="18" />
          &nbsp;
        </Fragment>
      )}
      <Themed.a
        as={Link}
        to={`/services/categories/${category.slug}`}
        sx={{
          color: "textGray",
          fontSize: [0, 1, null, null, null],
        }}
      >
        {category.name}
      </Themed.a>
    </li>
  )
}
export default ServiceTag
