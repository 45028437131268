/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"

const ServiceTemplateLanguages = ({ service, hasAddress }) => {
  return (
    <div>
      <Themed.h2
        sx={{
          fontSize: [2, 3, null, null, null],
          mt: hasAddress ? 4 : 0,
        }}
      >
        Languages
      </Themed.h2>
      <Themed.p
        sx={{
          fontStyle: "italic",
          color: "textGray",
          fontSize: 1,
          mb: 0,
          lineHeight: "snug",
        }}
      >
        Services available in these languages
      </Themed.p>
      <ul
        sx={{
          pl: 3,
          mt: 0,
        }}
      >
        {service.languages.map((language) => (
          <li
            key={language.slug.current}
            sx={{
              fontSize: 1,
            }}
          >
            <Themed.a
              as={Link}
              to={`/services/categories/${language.slug.current}`}
            >
              {language.name}
            </Themed.a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ServiceTemplateLanguages
