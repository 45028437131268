/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import PageH1 from "../components/page-templates/page-h1"
import ErrorForm from "../components/forms/error-form"
import { Helmet } from "react-helmet"

const NetlifyErrorFormPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Seo title="Netlify Static Error Form" />
      <PageH1>Netlify Static Error Form</PageH1>
      <div sx={{ mb: 5 }}>
        <Themed.p>
          This form is not functional, and is here for Netlify robots to pickup,
          so the error reports work. You can access the proper error report
          forms on the bottom of each service page.
        </Themed.p>
        <ErrorForm />
      </div>
    </Layout>
  )
}

export default NetlifyErrorFormPage
