/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import { Fragment, useState } from "react"
import ErrorModal from "./service-template-error-modal"

const ServiceTemplateFooter = ({ service }) => {
  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const handleError = () => {
    setIsErrorOpen(true)
  }

  return (
    <Fragment>
      <div>
        <Themed.p sx={{ fontSize: 0, color: "textGray", textAlign: "right" }}>
          Last updated: {service._updatedAt}
          <br />
          <Themed.a
            as={Link}
            to="/request-changes/"
            state={{ serviceId: service._id }}
          >
            Request changes
          </Themed.a>
          &nbsp;&mdash;&nbsp;
          <Themed.a
            as="button"
            aria-haspopup="true"
            onClick={handleError}
            sx={{
              border: "none",
              background: "none",
              cursor: "pointer",
              textDecoration: "underline",
              p: 0,
            }}
          >
            Report Error
          </Themed.a>
        </Themed.p>
      </div>
      {isErrorOpen && (
        <ErrorModal setIsErrorOpen={setIsErrorOpen} service={service} />
      )}
    </Fragment>
  )
}

export default ServiceTemplateFooter
