/** @jsx jsx */
import { jsx, useThemeUI, Themed, Button } from "theme-ui"
import { Link } from "gatsby"
import { useHomeContent } from "../../utils/hooks/use-home-content"
import HomeArticleCard from "./home-article-card"
import ReactRough, { Circle } from "react-rough"

const HomeArticles = () => {
  const { articleTitle, featuredArticles } = useHomeContent()
  const { theme } = useThemeUI()
  const mutedGreen = theme.colors.mutedGreen
  const accentColor = theme.colors.accent

  return (
    <section
      sx={{
        width: "100vw",
        position: "relative",
        left: "calc(-50vw + 50%)",
        mx: "auto",
        my: 5,
        zIndex: "20",
      }}
    >
      <svg
        viewBox="0 0 1440 100"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        aria-hidden={true}
      >
        <path
          id="Path"
          d="M0 0 L120 0 C240 0 480 0 720 11.263 960 22.105 1200 45.263 1320 56.105 L1440 67.368 1440 100 1320 100 C1200 100 960 100 720 100 480 100 240 100 120 100 L0 100 Z"
          fill={mutedGreen}
          fillOpacity="1"
          stroke="none"
        />
      </svg>
      <div
        sx={{
          bg: "mutedGreen",
          mt: -3,
          pb: [4, null, 2, null, null],
          pt: [4, null, 2, null, null],
        }}
      >
        <Themed.h2 sx={{ textAlign: "center", mt: 0, mb: 2, mx: 3 }}>
          {articleTitle}
        </Themed.h2>
        <div
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <ReactRough renderer="svg" width={72} height={18}>
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={9}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={36}
              y={9}
            />
            <Circle
              diameter={16}
              fill={accentColor}
              hachureGap={2}
              x={63}
              y={9}
            />
          </ReactRough>
        </div>
        <div
          sx={{
            mx: [3, null, null, 4, null],
            display: "flex",
            justifyContent: "center",
            alignItems: ["center", null, "stretch", null, null],
            flexDirection: ["column", null, "row", null, null],
          }}
        >
          {featuredArticles.map((article) => {
            if (article.article == null) {
              return null
            }
            return (
              <HomeArticleCard
                sx={{
                  mr: [3, null, null, 4, null],
                  mb: [4, null, 0, null, null],
                  maxWidth: "500px",
                  ":last-of-type": { mr: 0, mb: 0 },
                }}
                key={article.article.id}
                article={article.article}
                image={article.image}
              />
            )
          })}
        </div>
        <div sx={{ mx: 3, mt: 4, display: "flex", justifyContent: "center" }}>
          <Button as={Link} to="/articles/" variant="homeCta">
            View all articles
          </Button>
        </div>
      </div>
      <svg
        viewBox="0 0 1440 100"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        aria-hidden={true}
      >
        <path
          id="Path"
          d="M0 73.394 L120 70.963 C240 68.349 480 37.761 720 44.963 960 52.44 1200 80.275 1320 90.229 L1440 100 1440 0 1320 0 C1200 0 960 0 720 0 480 0 240 0 120 0 L0 0 Z"
          fill={mutedGreen}
          fillOpacity="1"
          stroke="none"
        />
      </svg>
    </section>
  )
}

export default HomeArticles
