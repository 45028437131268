/** @jsx jsx */
import { jsx, Themed, Button, useThemeUI } from "theme-ui"
import { Link } from "gatsby"
import { useHomeContent } from "../../utils/hooks/use-home-content"
import SanityContent from "../sanity/sanity-content"
import ReactRough, { Circle } from "react-rough"
import { GatsbyImage } from "gatsby-plugin-image"

const HomeContribute = () => {
  const { contributeContent, contributeTitle, contributeImage } =
    useHomeContent()
  const { theme } = useThemeUI()
  const accentColor = theme.colors.accent

  return (
    <section sx={{ position: "relative", mb: 6 }}>
      <Themed.h2 sx={{ mb: 2, mx: 3, textAlign: "center" }}>
        {contributeTitle}
      </Themed.h2>
      <div
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <ReactRough renderer="svg" width={72} height={18}>
          <Circle diameter={16} fill={accentColor} hachureGap={2} x={9} y={9} />
          <Circle
            diameter={16}
            fill={accentColor}
            hachureGap={2}
            x={36}
            y={9}
          />
          <Circle
            diameter={16}
            fill={accentColor}
            hachureGap={2}
            x={63}
            y={9}
          />
        </ReactRough>
      </div>
      <div
        sx={{
          display: ["block", null, "flex", null, null],
          alignItems: "center",
        }}
      >
        <GatsbyImage
          image={contributeImage.asset.gatsbyImageData}
          sx={{
            width: ["80px", "120px", "150px", "180px", null],
            float: "left",
            mr: 4,
            flexShrink: "0",
          }}
          objectFit="contain"
          imgStyle={{ objectFit: "contain" }}
          alt="Illustration of two people talking"
        />

        <SanityContent data={contributeContent} />
      </div>
      <div sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button as={Link} to="/add-a-service/" variant="homeCta">
          Add a service
        </Button>
      </div>
    </section>
  )
}

export default HomeContribute
