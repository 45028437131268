/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import { Fragment, useContext, useState } from "react"
import NavUlDropdown from "./nav-ul-submenu"
import NavLiDropdown from "./nav-li-submenu"
import DropdownButton from "./nav-links-dropdown-button"
import { useSiteMetadata } from "../../../../utils/hooks/use-site-metadata"
import { NavContext } from "../../../../utils/context/nav-context"

// This component has a lot going on. It is handling the mapping of the menu items, optionally using anchor links, and optionally showing a dropdown menu. It is broken into smaller components for readability here but could be condensed into one mega component if you wanted.

const NavLinksLeft = () => {
  const { menuLinks } = useSiteMetadata()
  const leftLinks = menuLinks.filter((link) => link.location === "left")
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [isNavOpen, setIsNavOpen] = useContext(NavContext) // eslint-disable-line

  return (
    <ul
      sx={{
        display: "flex",
        flexDirection: ["column", null, "row", null, null],
        alignItems: "center",
        flexWrap: "wrap",
        listStyle: "none",
        m: 0,
        p: 0,
        variant: "variants.navLinksLeft",
      }}
    >
      {leftLinks.map((link) => {
        const hasSubmenu = link.subMenu && link.subMenu.length > 0
        return (
          <li
            key={link.name}
            sx={{
              fontSize: [2, null, 1, 2, null],
              my: [2, null, 0, null, null],
              mr: [0, null, 2, 3, null],
              cursor: "pointer",
              ":last-of-type": {
                mr: 0,
              },
              ".active": {
                color: "primary",
                "::after": {
                  position: "absolute",
                  top: "100%",
                  left: "0",
                  width: "100%",
                  height: "4px",
                  backgroundColor: "primary",
                  content: "''",
                  opacity: "1",
                  transform: "translateY(0px)",
                },
              },
            }}
          >
            {hasSubmenu ? (
              <Fragment>
                <DropdownButton
                  link={link.name}
                  activeDropdown={activeDropdown}
                  setActiveDropdown={setActiveDropdown}
                >
                  {link.name}
                </DropdownButton>
                {activeDropdown === link.name && (
                  <NavUlDropdown
                    setActiveDropdown={setActiveDropdown}
                    activeDropdown={activeDropdown}
                    link={link.link}
                  >
                    {link.subMenu.map((subLink) => (
                      <NavLiDropdown key={subLink.name}>
                        <Themed.a
                          as={Link}
                          to={subLink.link}
                          activeClassName="active"
                          onClick={() => setIsNavOpen(false)}
                        >
                          {subLink.name}
                        </Themed.a>
                      </NavLiDropdown>
                    ))}
                  </NavUlDropdown>
                )}
              </Fragment>
            ) : (
              <Themed.a
                sx={{
                  position: "relative",
                  py: 1,
                  px: 1,
                  color: isNavOpen ? "header.textOpen" : "header.text",
                  textDecoration: "none",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  zIndex: 2,
                  ":hover, :focus, :active": {
                    color: "primary",
                  },
                  "::after": {
                    position: "absolute",
                    borderRadius: "9999em",
                    top: "100%",
                    left: "0",
                    width: "100%",
                    height: "1px",
                    backgroundColor: "primary",
                    content: "''",
                    opacity: "0",
                    transition: "height 0.3s, opacity 0.3s, transform 0.3s",
                    transform: "translateY(-6px)",
                  },
                  ":hover::after, :active::after, :focus::after": {
                    height: "4px",
                    opacity: "1",
                    transform: "translateY(0px)",
                  },
                }}
                as={Link}
                to={link.link}
                activeClassName="active"
                onClick={() => setIsNavOpen(false)}
              >
                {link.name}
              </Themed.a>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default NavLinksLeft
