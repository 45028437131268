/** @jsx jsx */
import { jsx } from "theme-ui"
import { darken } from "@theme-ui/color"

const Checkbox = ({ checkbox, register, prefix }) => {
  return (
    <div sx={{ position: "relative", mr: 4, display: "inline-flex" }}>
      <label
        sx={{
          fontSize: 1,
          cursor: "pointer",
          position: "relative",
          pl: "28px",
          mb: 2,
          // Hover styles for the checkbox
          ":hover input ~ .controlIndicator, input:focus ~ .controlIndicator": {
            bg: darken("muted", 0.15),
          },
          // Style when checked
          "input:checked ~ .controlIndicator": {
            bg: "primary",
          },
          // Displaying the checkbox
          "input:checked ~ .controlIndicator:after": {
            display: "block",
          },
          // Styles for when hovered and checked
          ":hover input:not([disabled]):checked ~ .controlIndicator, input:checked:focus ~ .controlIndicator":
            {
              bg: darken("primary", 0.15),
            },
        }}
      >
        {checkbox.name}
        <input
          type="checkbox"
          value={checkbox._id}
          {...register(`${prefix}-${checkbox.slug.current}`)}
          sx={{
            position: "absolute",
            zIndex: "-1",
            opacity: "0",
          }}
        />
        <div
          className="controlIndicator"
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            height: "24px",
            width: "24px",
            border: "1px solid #aaa",
            bg: "muted",
            ":after": {
              content: `""`,
              position: "absolute",
              display: "none",
              left: "9px",
              top: "3px",
              width: "6px",
              height: "14px",
              borderColor: "#fff",
              borderStyle: "solid",
              borderWidth: "0 2px 2px 0",
              transform: "rotate(45deg)",
            },
          }}
        />
      </label>
    </div>
  )
}

export default Checkbox
