/** @jsx jsx */
import { jsx } from "theme-ui"
import { darken } from "@theme-ui/color"

const CheckboxHome = ({ register, label, slug, category }) => {
  return (
    <div sx={{ position: "relative", width: "100px" }}>
      <label
        sx={{
          fontSize: [0, 1, null, null, null],
          lineHeight: "tight",
          cursor: "pointer",
          position: "relative",
          mb: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontWeight: "bold",
          letterSpacing: "wide",
          // Hover styles for the checkbox
          ":hover input ~ .controlIndicator, input:focus ~ .controlIndicator": {
            bg: darken("mutedGreen", 0.15),
          },
          // Style when checked
          "input:checked ~ .controlIndicator": {
            bg: "highlight",
            borderColor: "highlight",
          },
          // Displaying the checkbox
          "input:checked ~ .controlIndicator:after": {
            display: "block",
          },
          // Styles for when hovered and checked
          ":hover input:not([disabled]):checked ~ .controlIndicator, input:checked:focus ~ .controlIndicator":
            {
              bg: "accent",
              borderColor: "accent",
            },
        }}
      >
        <input
          type="checkbox"
          {...register(`popularFilters.${category}.${slug}`)}
          sx={{
            position: "absolute",
            zIndex: "-1",
            opacity: "0",
          }}
        />
        <div
          className="controlIndicator"
          sx={{
            position: "relative",
            height: ["48px", null, "56px", null, null],
            width: ["48px", null, "56px", null, null],
            borderWidth: "4px",
            borderStyle: "solid",
            borderColor: "accent",
            borderRadius: "9999em",
            bg: "mutedGreen",
            mb: 2,
            boxShadow: "lg",
            ":after": {
              content: `""`,
              position: "absolute",
              display: "none",
              left: ["14px", null, "18px", null, null],
              top: ["4px", null, "7px", null, null],
              width: "12px",
              height: "28px",
              borderColor: "#fff",
              borderStyle: "solid",
              borderWidth: "0 4px 4px 0",
              transform: "rotate(45deg)",
            },
          }}
        />
        {label}
      </label>
    </div>
  )
}

export default CheckboxHome
