/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import HomeHero from "../components/home/home-hero"
import HomeArticles from "../components/home/home-articles"
import HomeCrisis from "../components/home/home-crisis"
import HomeServices from "../components/home/home-services"
import HomePartnership from "../components/home/home-partnership"
import HomeAbout from "../components/home/home-about"
import HomeContribute from "../components/home/home-contribute"

const HomePage = () => {
  return (
    <Layout>
      <Seo title="Find Help You Can Trust" />
      <HomeHero />
      <HomePartnership />
      <HomeArticles />
      <HomeCrisis />
      <HomeServices />
      <HomeAbout />
      <HomeContribute />
    </Layout>
  )
}

export default HomePage
