// Search logic
import { useContext } from "react"
import { AllResultsContext } from "../context/allResults-context"
import { SearchResultsContext } from "../context/searchResults-context"
import { FilterValuesContext } from "../context/filterValues-context"
import { HasPopularFiltersContext } from "../context/hasPopularFilters-context"
import { HasQueryContext } from "../context/hasQuery-context"
import { IsFilterOpenContext } from "../context/isFilterOpen-context"
import { IsSheetOpenContext } from "../context/isSheetOpen-context"
import { ResetFiltersContext } from "../context/resetFilters-context"
import { FuseSearchListContext } from "../context/fuseSearchList-context"
import { FuseFilterListContext } from "../context/fuseFilterList-context"
import { SearchQueryContext } from "../context/searchQuery-context"
import { VisibleMarkersContext } from "../context/visibleMarkers-context"
import { HasQueryFromIndexContext } from "../context/hasQueryFromIndex-context"

export const useContextValues = () => {
  // CONTEXT
  // Get our context values
  const [allResults, setAllResults] = useContext(AllResultsContext)
  const [searchResults, setSearchResults] = useContext(SearchResultsContext)
  const [filterValues, setFilterValues] = useContext(FilterValuesContext)
  const [hasPopularFilters, setHasPopularFilters] = useContext(
    HasPopularFiltersContext
  )
  const [hasQuery, setHasQuery] = useContext(HasQueryContext)
  const [isFilterOpen, setIsFilterOpen] = useContext(IsFilterOpenContext)
  const [isSheetOpen, setIsSheetOpen] = useContext(IsSheetOpenContext)
  const [resetFilters, setResetFilters] = useContext(ResetFiltersContext)
  const [fuseSearchList, setFuseSearchList] = useContext(FuseSearchListContext)
  const [fuseFilterList, setFuseFilterList] = useContext(FuseFilterListContext)
  const [searchQuery, setSearchQuery] = useContext(SearchQueryContext)
  const [visibleMarkers, setVisibleMarkers] = useContext(VisibleMarkersContext)
  const [hasQueryFromIndex, setHasQueryFromIndex] = useContext(
    HasQueryFromIndexContext
  )

  return {
    hasPopularFilters,
    setHasPopularFilters,
    isFilterOpen,
    setIsFilterOpen,
    isSheetOpen,
    setIsSheetOpen,
    fuseSearchList,
    setFuseSearchList,
    resetFilters,
    setResetFilters,
    hasQuery,
    setHasQuery,
    searchQuery,
    setSearchQuery,
    searchResults,
    setSearchResults,
    allResults,
    setAllResults,
    filterValues,
    setFilterValues,
    fuseFilterList,
    setFuseFilterList,
    visibleMarkers,
    setVisibleMarkers,
    hasQueryFromIndex,
    setHasQueryFromIndex,
  }
}
