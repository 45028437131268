/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { useSiteMetadata } from "../../utils/hooks/use-site-metadata"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SanityContent from "../sanity/sanity-content"

const SiteFooter = ({ isMap }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityFooter(limit: 1, sort: { fields: _updatedAt, order: DESC }) {
        nodes {
          _rawRecognitionText
          _rawLandRecognition
          _rawFooterContact
          footerLinks {
            link
            name
          }
          footerLogo {
            alt
            asset {
              id
              gatsbyImageData(height: 150)
            }
          }
          partnerLogos {
            link
            alt
            asset {
              id
              gatsbyImageData(height: 80)
            }
          }
        }
      }
    }
  `)
  const { title } = useSiteMetadata()
  const footerData = data.allSanityFooter.nodes[0]
  return (
    <footer
      sx={{
        gridArea: "footer",
      }}
    >
      <div
        sx={{
          width: "100%",
          maxWidth: "maxPageWidth",
          mx: "auto",
          backgroundColor: "accent",
          color: "#ffffff",
          fontSize: 1,
          pb: 1,
          a: {
            color: "#ffffff",
            ":hover, :focus, :active": {
              color: "mutedPurple",
            },
          },
        }}
      >
        <div
          sx={{
            bg: "mutedPurple",
            color: "text",
            px: 3,
            py: 2,
            textAlign: "center",
            p: { m: 0, fontSize: [0, null, 1, null, null] },
          }}
        >
          <SanityContent data={footerData._rawLandRecognition} />
        </div>
        <div
          sx={{
            px: isMap ? 4 : [3, null, 5, 6, 6],
            pt: 3,
            display: "grid",
            gridGap: 5,
            gridTemplateColumns: isMap
              ? "minmax(0, 1fr)"
              : [
                  "minmax(0, 1fr)",
                  "minmax(0, 1fr) minmax(0, 1fr)",
                  null,
                  "20% minmax(0, 1fr) 20%",
                  null,
                ],
          }}
        >
          <div
            sx={{
              gridColumn: isMap
                ? "1 / -1"
                : ["1 / -1", "1 / 2", null, "1 / 2", null],
              gridRow: isMap
                ? "2 / 3"
                : ["2 / 3", "2 / 3", null, "1 / 2", null],
              justifySelf: isMap
                ? "start"
                : ["start", null, "center", "start", null],
            }}
          >
            <Themed.p sx={{ fontWeight: "bold" }}>Navigation</Themed.p>
            <ul sx={{ listStyle: "none", m: 0, p: 0 }}>
              {footerData.footerLinks.map((link) => (
                <li key={link.link}>
                  <Themed.a as={Link} to={link.link}>
                    {link.name}
                  </Themed.a>
                </li>
              ))}
            </ul>
          </div>
          <div
            sx={{
              gridColumn: isMap
                ? "1 / -1"
                : ["1 / -1", "1 / -1", null, "2 / 3", null],
              gridRow: isMap
                ? "1 / 2"
                : ["1 / 2", "1 / 2", null, "1 / 2", null],
            }}
          >
            <div
              sx={{
                textAlign: isMap
                  ? "left"
                  : ["left", "center", null, null, null],
                p: { fontSize: [0, null, 1, null, null] },
              }}
            >
              <SanityContent data={footerData._rawRecognitionText} />
            </div>
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {footerData.partnerLogos.map((partner) => (
                <div
                  key={partner.alt}
                  sx={{
                    maxWidth: "140px",
                    minWidth: "100px",
                    flex: "1",
                    mr: 3,
                    mb: 3,
                    ":last-of-type": {
                      mr: 0,
                    },
                  }}
                >
                  <a href={partner.link}>
                    <GatsbyImage
                      image={partner.asset.gatsbyImageData}
                      alt={partner.alt}
                      objectFit="contain"
                      sx={{ height: "40px" }}
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div
            sx={{
              gridColumn: isMap
                ? "1 / -1"
                : ["1 / -1", "2 / 3", null, "3 / 4", null],
              gridRow: isMap
                ? "3 / 4"
                : ["3 / 4", "2 / 3", null, "1 / 2", null],
              justifySelf: isMap
                ? "start"
                : ["start", "end", "center", "end", null],
            }}
          >
            <GatsbyImage
              image={footerData.footerLogo.asset.gatsbyImageData}
              alt={footerData.footerLogo.alt}
              sx={{ width: "150px" }}
            />
            <div sx={{ mr: 3, p: { fontSize: [0, null, 1, null, null] } }}>
              <SanityContent data={footerData._rawFooterContact} />
            </div>
          </div>
        </div>
        <Themed.p sx={{ fontSize: 0, textAlign: "center", mb: 0, mt: 4 }}>
          <Themed.a as={Link} to="/privacy">
            Privacy policy
          </Themed.a>{" "}
          &mdash;{" "}
          <Themed.a as={Link} to="/terms">
            Terms of use
          </Themed.a>
        </Themed.p>
        <Themed.p sx={{ fontSize: 0, textAlign: "center", mt: 0 }}>
          © {new Date().getFullYear()} {title}
        </Themed.p>
      </div>
    </footer>
  )
}

export default SiteFooter
