/** @jsx jsx */
import { jsx, Button } from "theme-ui"
import { darken } from "@theme-ui/color"
import { useEffect, useRef } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useCategories } from "../../utils/hooks/use-categories"
import { useClearedFilters } from "../../utils/hooks/use-cleared-filters"
import { useDetectOutsideClick } from "../../utils/hooks/use-detect-outside"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import SearchForm from "../search/search-form"
import Checkbox from "../forms/checkbox-filter"
import { useFilter } from "../../utils/hooks/use-filter"
import { useContextValues } from "../../utils/hooks/use-context-values"

const FilterMenu = () => {
  // Get state and setState
  const {
    setResetFilters,
    filterValues,
    resetFilters,
    searchQuery,
    hasPopularFilters,
    fuseFilterList,
  } = useContextValues()

  // Get filter function
  const { handleFilter } = useFilter()

  //Get the category info
  const {
    serviceCategories,
    serviceFeatures,
    serviceLanguages,
    serviceCosts,
    serviceFormats,
  } = useCategories()

  // Get the cleared form
  const clearedFilters = useClearedFilters()

  // Initiate forms
  const { register, reset, control } = useForm({
    defaultValues: filterValues,
  })

  // Watch the results
  const watchedResults = useWatch({ control })

  // If it has results, then run the filter.
  useEffect(() => {
    const hasResults =
      Object.keys(watchedResults).length > 0 &&
      watchedResults.constructor === Object
    if (fuseFilterList != null && hasResults && !hasPopularFilters) {
      handleFilter(watchedResults)
    }
  }, [watchedResults, fuseFilterList]) //eslint-disable-line

  // Reset the results to be the telhealth filters on load, if display telehealth is true, only effects non-modal loads of filter form
  useEffect(() => {
    if (hasPopularFilters) {
      const filters = {
        languages: {},
        features: searchQuery.popularFilters.features
          ? { ...searchQuery.popularFilters.features }
          : {},
        formats: searchQuery.popularFilters.formats
          ? { ...searchQuery.popularFilters.formats }
          : {},
        categories: searchQuery.popularFilters.categories
          ? { ...searchQuery.popularFilters.categories }
          : {},
        costs: searchQuery.popularFilters.costs
          ? { ...searchQuery.popularFilters.costs }
          : {},
      }
      reset({ ...filters })
    }
  }, []) //eslint-disable-line

  // Reset the filters if the search result is cleared or changed
  useEffect(() => {
    if (resetFilters) {
      reset(clearedFilters)
      setResetFilters(false)
    }
  }, [resetFilters]) //eslint-disable-line

  // Ref for dropdown menus
  const categoryRef = useRef()
  const featureRef = useRef()
  const costRef = useRef()
  const languageRef = useRef()
  const formatRef = useRef()

  // Toggle for open closed states
  const [isCategoryOpen, setIsCategoryOpen] = useDetectOutsideClick(
    categoryRef,
    false
  )

  const [isFeatureOpen, setIsFeatureOpen] = useDetectOutsideClick(
    featureRef,
    false
  )

  const [isFormatOpen, setIsFormatOpen] = useDetectOutsideClick(
    formatRef,
    false
  )

  const [isCostOpen, setIsCostOpen] = useDetectOutsideClick(costRef, false)

  const [isLanguageOpen, setIsLanguageOpen] = useDetectOutsideClick(
    languageRef,
    false
  )

  return (
    <div
      sx={{
        borderBottomColor: "muted",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderTopColor: "muted",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        display: "flex",
        alignItems: "center",
        px: 3,
        position: "relative",
        zIndex: "6000",
      }}
    >
      <div
        sx={{
          flex: "2",
          maxWidth: ["300px", null, null, "39vw", "29vw"],
          height: "50px",
          width: "100%",
          pr: 3,
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "muted",
          display: "grid",
          placeItems: "center",
        }}
      >
        <SearchForm isMap />
      </div>
      <form>
        <ul
          sx={{
            display: "flex",
            m: 0,
            p: 0,
            listStyle: "none",
            fontSize: [1, null, null, null, 2, null],
          }}
        >
          <li sx={{ position: "relative" }}>
            <Button
              aria-haspopup="true"
              aria-expanded={isCostOpen ? "true" : "false"}
              variant="filterMenuToggle"
              type="button"
              onClick={() => (
                setIsCostOpen(!isCostOpen), //eslint-disable-line
                setIsLanguageOpen(false),
                setIsFeatureOpen(false),
                setIsCategoryOpen(false),
                setIsFormatOpen(false)
              )}
            >
              Costs {isCostOpen ? <FiChevronUp /> : <FiChevronDown />}
            </Button>
            {isCostOpen && (
              <div
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "50%",
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "0 12.5px 15px 12.5px",
                  borderTopColor: "transparent",
                  borderRightColor: "transparent",
                  borderLeftColor: "transparent",
                  borderBottomColor: darken("background", 0.15),
                  zIndex: "10000",
                }}
              />
            )}
            <ul
              ref={costRef}
              sx={{
                display: isCostOpen ? "block" : "none",
                bg: darken("background", 0.15),
                position: "absolute",
                top: "50px",
                left: "0",
                m: 0,
                p: 3,
                listStyle: "none",
                fontSize: [1, null, null, null, 2, null],
                width: "200px",
                boxShadow: "lg",
              }}
            >
              {serviceCosts.map((cost) => (
                <li key={cost.id}>
                  <Checkbox
                    checkbox={cost}
                    register={register}
                    prefix="costs"
                  />
                </li>
              ))}
            </ul>
          </li>
          <li sx={{ position: "relative" }}>
            <Button
              aria-haspopup="true"
              aria-expanded={isFeatureOpen ? "true" : "false"}
              variant="filterMenuToggle"
              type="button"
              onClick={() => (
                setIsFeatureOpen(!isFeatureOpen), //eslint-disable-line
                setIsCostOpen(false),
                setIsLanguageOpen(false),
                setIsCategoryOpen(false),
                setIsFormatOpen(false)
              )}
            >
              Features {isFeatureOpen ? <FiChevronUp /> : <FiChevronDown />}
            </Button>
            {isFeatureOpen && (
              <div
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "50%",
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "0 12.5px 15px 12.5px",
                  borderTopColor: "transparent",
                  borderRightColor: "transparent",
                  borderLeftColor: "transparent",
                  borderBottomColor: darken("background", 0.15),
                  zIndex: "10000",
                }}
              />
            )}
            <ul
              ref={featureRef}
              sx={{
                display: isFeatureOpen ? "block" : "none",
                bg: darken("background", 0.15),
                position: "absolute",
                top: "50px",
                left: "0",
                m: 0,
                p: 3,
                listStyle: "none",
                fontSize: [1, null, null, null, 2, null],
                width: "280px",
                boxShadow: "lg",
              }}
            >
              {serviceFeatures.map((feature) => (
                <li key={feature.id}>
                  <Checkbox
                    checkbox={feature}
                    register={register}
                    prefix="features"
                  />
                </li>
              ))}
            </ul>
          </li>
          <li sx={{ position: "relative" }}>
            <Button
              aria-haspopup="true"
              aria-expanded={isCategoryOpen ? "true" : "false"}
              variant="filterMenuToggle"
              type="button"
              onClick={() => (
                setIsCategoryOpen(!isCategoryOpen), //eslint-disable-line
                setIsCostOpen(false),
                setIsFeatureOpen(false),
                setIsLanguageOpen(false),
                setIsFormatOpen(false)
              )}
            >
              Types
              {isCategoryOpen ? <FiChevronUp /> : <FiChevronDown />}
            </Button>
            {isCategoryOpen && (
              <div
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "50%",
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "0 12.5px 15px 12.5px",
                  borderTopColor: "transparent",
                  borderRightColor: "transparent",
                  borderLeftColor: "transparent",
                  borderBottomColor: darken("background", 0.15),
                  zIndex: "10000",
                }}
              />
            )}
            <ul
              ref={categoryRef}
              sx={{
                display: isCategoryOpen ? "block" : "none",
                bg: darken("background", 0.15),
                position: "absolute",
                top: "50px",
                left: "0",
                m: 0,
                p: 3,
                listStyle: "none",
                fontSize: [1, null, null, null, 2, null],
                width: "300px",
                boxShadow: "lg",
              }}
            >
              {serviceCategories.map((category) => (
                <li key={category.id}>
                  <Checkbox
                    prefix="categories"
                    checkbox={category}
                    register={register}
                  />
                </li>
              ))}
            </ul>
          </li>
          <li sx={{ position: "relative" }}>
            <Button
              aria-haspopup="true"
              aria-expanded={isFormatOpen ? "true" : "false"}
              variant="filterMenuToggle"
              type="button"
              onClick={() => (
                setIsFormatOpen(!isFormatOpen), //eslint-disable-line
                setIsCostOpen(false),
                setIsLanguageOpen(false),
                setIsCategoryOpen(false),
                setIsFeatureOpen(false)
              )}
            >
              Formats {isFormatOpen ? <FiChevronUp /> : <FiChevronDown />}
            </Button>
            {isFormatOpen && (
              <div
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "50%",
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "0 12.5px 15px 12.5px",
                  borderTopColor: "transparent",
                  borderRightColor: "transparent",
                  borderLeftColor: "transparent",
                  borderBottomColor: darken("background", 0.15),
                  zIndex: "10000",
                }}
              />
            )}
            <ul
              ref={formatRef}
              sx={{
                display: isFormatOpen ? "block" : "none",
                bg: darken("background", 0.15),
                position: "absolute",
                top: "50px",
                right: "-70px",
                m: 0,
                p: 3,
                listStyle: "none",
                fontSize: [1, null, null, null, 2, null],
                width: "250px",
                boxShadow: "lg",
              }}
            >
              {serviceFormats.map((format) => (
                <li key={format.id}>
                  <Checkbox
                    checkbox={format}
                    register={register}
                    prefix="formats"
                  />
                </li>
              ))}
            </ul>
          </li>
          <li sx={{ position: "relative" }}>
            <Button
              aria-haspopup="true"
              aria-expanded={isLanguageOpen ? "true" : "false"}
              variant="filterMenuToggle"
              type="button"
              onClick={() => (
                setIsLanguageOpen(!isLanguageOpen), //eslint-disable-line
                setIsCostOpen(false),
                setIsFeatureOpen(false),
                setIsCategoryOpen(false),
                setIsFormatOpen(false)
              )}
            >
              Languages {isLanguageOpen ? <FiChevronUp /> : <FiChevronDown />}
            </Button>
            {isLanguageOpen && (
              <div
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "50%",
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "0 12.5px 15px 12.5px",
                  borderTopColor: "transparent",
                  borderRightColor: "transparent",
                  borderLeftColor: "transparent",
                  borderBottomColor: darken("background", 0.15),
                  zIndex: "10000",
                }}
              />
            )}
            <ul
              ref={languageRef}
              sx={{
                display: isLanguageOpen ? "grid" : "none",
                gridTemplateColumns: [
                  null,
                  null,
                  "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
                  null,
                  null,
                ],
                bg: darken("background", 0.15),
                position: "absolute",
                top: "50px",
                right: "0",
                m: 0,
                p: 3,
                listStyle: "none",
                fontSize: [1, null, null, null, 2, null],
                width: "600px",
                boxShadow: "lg",
              }}
            >
              {serviceLanguages.map((language) => (
                <li key={language.id}>
                  <Checkbox
                    checkbox={language}
                    register={register}
                    prefix="languages"
                  />
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </form>
    </div>
  )
}

export default FilterMenu
