/** @jsx jsx */
import { jsx, Themed, useThemeUI } from "theme-ui"
import { useHomeContent } from "../../utils/hooks/use-home-content"
import HomeSearchForm from "./home-hero-search"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

const HomePage = () => {
  const { bannerImage, bannerTitle } = useHomeContent()
  const { theme } = useThemeUI()
  const backgroundColor = theme.colors.background
  const bgImage = convertToBgImage(bannerImage)

  return (
    <section
      sx={{
        width: "100vw",
        height: "auto",
        left: "calc(-50vw + 50%)",
        position: "relative",
      }}
    >
      <BackgroundImage
        tag="div"
        {...bgImage}
        backgroundColor={backgroundColor}
        sx={{
          "::before, ::after": {
            backgroundSize: [
              "cover !important",
              null,
              "contain !important",
              null,
              null,
            ],
          },
        }}
      >
        <div
          sx={{
            py: [4, 5, null, null, null],
            px: 3,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Themed.h1
            sx={{
              color: "black",
              mt: 0,
              mb: 3,
              fontSize: [6, null, null, 7, 8],
              // textShadow: "0 2px 4px rgba(0,0,0,0.10)",
            }}
          >
            {bannerTitle}
          </Themed.h1>
          <HomeSearchForm />
        </div>
      </BackgroundImage>
    </section>
  )
}

export default HomePage
