/** @jsx jsx */
import { jsx, Themed, useThemeUI } from "theme-ui"
import { darken } from "@theme-ui/color"
import { FiPhone, FiExternalLink, FiMail } from "react-icons/fi"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

const ServiceTemplateHeader = ({ bannerImage, service }) => {
  const hasPhone = service?.contact?.phone != null
  const hasEmail = service?.contact?.email != null
  const hasWebsite = service?.contact?.website != null

  const { theme } = useThemeUI()
  const backgroundColor = theme.colors.background
  const gap = parseInt(theme.space[4]) * 16
  const maxContentWidth = parseInt(theme.sizes.maxContentWidth) + gap + 300
  const bgImage = convertToBgImage(bannerImage)

  return (
    <div>
      <BackgroundImage
        tag="div"
        {...bgImage}
        backgroundColor="#f6f6f6"
        sx={{
          "::before, ::after": {
            filter: "opacity(0.4)",
          },
        }}
      >
        <div
          sx={{
            pt: 5,
            pb: 6,
            mx: [3, null, "auto", null, null],
            width: ["auto", null, "100%", null, null],
            maxWidth: `${maxContentWidth}px`,
            alignSelf: "center",
            justifySelf: "left",
          }}
        >
          <Themed.h1
            sx={{
              my: 0,
              ml: [0, null, 3, 0, null],
              fontSize: [3, 5, 6, null, null],
              lineHeight: ["1.7em", "1.65em", "1.55em", null, null],
            }}
          >
            <span
              sx={{
                bg: "primary",
                color: "#ffffff",
                display: "inline",
                py: 2,
                px: [2, null, 3, null, null],
                borderRadius: "6px",
                boxDecorationBreak: "clone",
              }}
            >
              {service.name}
            </span>
          </Themed.h1>
          <ul
            sx={{
              display: "flex",
              flexDirection: ["column", "row", null, null, null],
              alignItems: "flex-start",
              m: 0,
              p: 0,
              my: 3,
              ml: [0, null, 3, 0, null],
              listStyle: "none",
            }}
          >
            {hasPhone && (
              <li
                sx={{
                  borderRadius: "9999em",
                  bg: "primary",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  mr: 2,
                  mb: [2, 0, null, null, null],
                  ":hover, :active, :focus-within": {
                    bg: darken("primary", 0.1),
                  },
                  ":last-of-type": {
                    mr: 0,
                    mb: 0,
                  },
                }}
              >
                <a
                  href={`tel:${service.contact.phone}`}
                  sx={{
                    px: [2, null, 3, null, null],
                    py: 1,
                    color: "white",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FiPhone />
                  <span sx={{ fontSize: [1, null, 2, null, null] }}>
                    &nbsp; {service.contact.phone}
                  </span>
                </a>
              </li>
            )}
            {hasWebsite && (
              <li
                sx={{
                  borderRadius: "9999em",
                  bg: "primary",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  mr: 2,
                  mb: [2, 0, null, null, null],
                  ":hover, :active, :focus-within": {
                    bg: darken("primary", 0.1),
                  },
                  ":last-of-type": {
                    mr: 0,
                    mb: 0,
                  },
                }}
              >
                <a
                  href={service.contact.website}
                  target="_blank"
                  rel="noreferrer noopener"
                  sx={{
                    px: [2, null, 3, null, null],
                    py: 1,
                    color: "white",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FiExternalLink />
                  <span sx={{ fontSize: [1, null, 2, null, null] }}>
                    &nbsp; Website
                  </span>
                </a>
              </li>
            )}
            {hasEmail && (
              <li
                sx={{
                  borderRadius: "9999em",
                  bg: "primary",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  mr: 2,
                  mb: [2, 0, null, null, null],
                  ":hover, :active, :focus-within": {
                    bg: darken("primary", 0.1),
                  },
                  ":last-of-type": {
                    mr: 0,
                    mb: 0,
                  },
                }}
              >
                <a
                  href={`mailto:${service.contact.email}`}
                  sx={{
                    px: [2, null, 3, null, null],
                    py: 1,
                    color: "white",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FiMail />
                  <span sx={{ fontSize: [1, null, 2, null, null] }}>
                    &nbsp; Email
                  </span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </BackgroundImage>
      <div
        sx={{
          mt: ["-20px", "-30px", "-50px", "-50px", null],
          maxHeight: "50px",
          position: "relative",
          zIndex: "10",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <svg
          viewBox="0 0 1440 100"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          aria-hidden={true}
          sx={{ maxHeight: "50px", width: "100%" }}
          preserveAspectRatio="none"
        >
          <path
            id="Path"
            d="M1440 0 L1320 0 C1200 0 960 0 720 11.263 480 22.105 240 45.263 120 56.105 L0 67.368 0 100 120 100 C240 100 480 100 720 100 960 100 1200 100 1320 100 L1440 100 Z"
            fill={backgroundColor}
            fillOpacity="1"
            stroke="none"
          />
        </svg>
      </div>
    </div>
  )
}

export default ServiceTemplateHeader
