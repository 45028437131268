/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Seo from "../components/layout/seo"
import Layout from "../components/layout/layout"
import SanityContent from "../components/sanity/sanity-content"
import PageH1 from "../components/page-templates/page-h1"

const PageTemplate = ({ data }) => {
  const page = data.sanityPage

  return (
    <Layout>
      <Seo title={page.title} />
      <PageH1>{page.title}</PageH1>
      <div sx={{ mb: 5 }}>
        <SanityContent data={page._rawBody} />
      </div>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query SanityPageQueryUpdated($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      title
      _rawBody(resolveReferences: { maxDepth: 1000 })
    }
  }
`
