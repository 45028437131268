/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import { useContextValues } from "../../utils/hooks/use-context-values"

const MapBottomSheet = ({ children }) => {
  const { isSheetOpen } = useContextValues()

  const variants = {
    open: { y: -300 },
    closed: { y: 0 },
  }

  return (
    <motion.div
      layout
      drag="y"
      dragConstraints={{ top: -350, bottom: 0 }}
      dragElastic={0.2}
      animate={isSheetOpen ? "open" : "closed"}
      variants={variants}
      dragPropagation
      sx={{
        height: "550px",
        width: "100%",
        maxWidth: "100vw",
        position: "relative",
        bg: "background",
        zIndex: "3000",
        transform: "translate3d(0,0,0)",
        boxShadow:
          "0 -3px 3px 0 rgba(0, 0, 0, 0.1), 0 -2px 2px 0 rgba(0, 0, 0, 0.06)",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
      }}
    >
      {children}
    </motion.div>
  )
}

export default MapBottomSheet
