/** @jsx jsx */
import { jsx } from "theme-ui"
import { useContext } from "react"
import { NavContext } from "../../../../utils/context/nav-context"

const Span = ({ open }) => (
  <span
    sx={{
      backgroundColor: "currentColor",
      display: "block",
      height: "2px",
      left: "calc(50% - 10px)",
      position: "absolute",
      transformOrigin: "center",
      transitionDuration: "86ms",
      transitionProperty: "background-color, opacity, transform",
      transitionTimingFunction: "ease-out",
      width: "20px",

      ":nth-of-type(1)": {
        top: "calc(50% - 8px)",
        transform: open && "translateY(7px) rotate(45deg)",
      },
      ":nth-of-type(2)": {
        top: "calc(50% - 1px)",
        opacity: open && "0",
      },
      ":nth-of-type(3)": {
        top: "calc(50% + 6px)",
        transform: open && "translateY(-7px) rotate(-45deg)",
      },
    }}
  ></span>
)

const SiteMobileButton = () => {
  const [isNavOpen, setIsNavOpen] = useContext(NavContext)
  return (
    <button
      aria-label="Toggle Menu"
      onClick={() => setIsNavOpen(!isNavOpen)}
      sx={{
        gridColumn: "2 / 3",
        gridRow: "1 / 2",
        alignSelf: "center",
        color: isNavOpen ? "header.textOpen" : "header.text",
        cursor: "pointer",
        display: ["flex", null, "none", null, null],
        position: "relative",
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        ml: "auto",
        pr: 0,
      }}
    >
      <div sx={{ display: "flex", alignItems: "center", mt: "-2px" }}>
        <span
          sx={{
            fontSize: 1,
            color: isNavOpen ? "header.textOpen" : "header.text",
          }}
        >
          {isNavOpen ? "Close" : "Menu"}
        </span>
      </div>
      <div sx={{ position: "relative", height: "48px", width: "36px" }}>
        <Span open={isNavOpen} />
        <Span open={isNavOpen} />
        <Span open={isNavOpen} />
      </div>
    </button>
  )
}

export default SiteMobileButton
